import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Contact from "./Contact/Contact";
import Home from "./Home/Home";
import Login from "./Login/Login";
import Register from "./Register/Register";
import "./Public.css";
import FAQ from "./FAQ/FAQ";
import Privacy from "./Privacy/Privacy";
import About from "./About/About";
import Verify from "./Verify/verify";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyRegistration from "./VerifyRegistration/VerifyRegistration";
import Terms from "./Terms/Terms";
import Docs from "./Docs/Docs";

const Public = () => {
  const page = useLocation()["pathname"];
  const pgarr = page.split("/");
  console.log("Page is::: ", page, "pgarr is: ", pgarr);
  return (
    <React.Fragment>
      {page !== "/" &&
        page !== "/login" &&
        page !== "/register" &&
        page !== "/docs" &&
        pgarr[1] !== "docs" &&
        page !== "/paper" &&
        pgarr[1] !== "paper" &&
        pgarr[1] !== "reset-password" &&
        pgarr[1] !== "verify-registration" &&
        page !== "" && (
          <>
            {/* 
            <HeaderTop /> */}
            <Header />
          </>
        )}
      <Routes>
        <Route path="/*" element={<Home />}></Route>
        <Route path="" element={<Home />}></Route>
        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route
          path="/reset-password/:id/:token"
          element={<ResetPassword />}
        ></Route>
        <Route
          path="/verify-registration/:id/:token"
          element={<VerifyRegistration />}
        ></Route>
        <Route path="/faq" element={<FAQ />}></Route>
        <Route path="/privacy-policy" element={<Privacy />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="docs//*" element={<Docs />}></Route>
        <Route path="/about-us" element={<About />}></Route>
      </Routes>
      {/*page !== "/" && page !== "" && <Footer />*/}
    </React.Fragment>
  );
};

export default Public;
