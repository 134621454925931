import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeoModule from "../../../services/SeoModule";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import LinearProgress from "@mui/material/LinearProgress";
import HttpService from "../../../services/HttpService";
const DocsHome = () => {
  let navigate = useNavigate();
  const params = useParams();
  const { decodeHtml, truncateWord } = processHtml;
  const [islug, setIslug] = React.useState<any>(null);
  const [whitepaper, setDocs] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    getDocs(islug);
  }, [params]);

  const getDocs = (slug: string | undefined | null) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("get_docs_page", { mode: "sub", slug: slug })
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.data) {
            setDocs(result.data);
          } else {
            setDocs({});
          }
        },
        (error) => {
          setDocs([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  return (
    <React.Fragment>
      <SeoModule
        title={`Reva - ${whitepaper?.title || "Docs...."}`}
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />

      <div className="page-head border-bottom">
        <div className="flex flex-row-resp">
          <div className="inline-block pxy20">
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                width: "100%",
                fontSize: "90% !important",
                fontStyle: "italic",
              }}
            >
              <Link to="/docs">Docs</Link>
              <Link to={`/docs/${whitepaper?.category_slug}`}>
                {loading ? "..." : whitepaper?.category_title}
              </Link>
            </Breadcrumbs>
            <h2 className="mt20">
              {loading ? "Loading..." : whitepaper?.title}
            </h2>
          </div>
        </div>
      </div>

      <div className="pxy20">
        {loading && <LinearProgress />}
        {!loading && (
          <>
            {whitepaper?.banner_url && (
              <div className="banner-spot">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_DOMAIN_API +
                    whitepaper?.banner_url
                  }
                  alt="Page Banner"
                />
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: decodeHtml(whitepaper?.description || ""),
              }}
            ></div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
export default DocsHome;
