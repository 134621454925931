import * as React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import AdminSidePanel from "./AdminSidePanel";
import AdminHeader from "./AdminHeader";
import Dashboard from "./Dashboard/Dashboard";
import Four0Four from "../public/Four0Four/Four0Four";
import AuthService from "../../services/AuthService";
import HttpService from "../../services/HttpService";
import Messages from "./Messages/Messages";
import Start from "./Start/Start";
import Test from "./Test";
import Scores from "./Scores/Scores";
import Settings from "./Settings";
import LeaderBoard from "./LeaderBoard";
import "../dashboard.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Admin() {
  console.log("Admin page Renders");
  const [redirect, setRedirect] = React.useState(false);
  const [to_verify, setToVerify] = React.useState(false);
  const [admin_verified, setAdminVerified] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [resending, setResending] = React.useState(false);
  const [loading_text, setLoadingText] = React.useState(
    "Please enter the verification code sent your phone or email"
  );
  const [verify_data, setVerifyData] = React.useState<any>({
    is_logged: false,
  });
  const usr = AuthService.getCurrentUser();
  let navigate = useNavigate();
  React.useEffect(() => {
    AuthService.checkSession().then(
      (res) => {
        console.log("SESSION CHECK RESULT:: ", res);
        if (!res.status || res.status === 0) {
          AuthService.logout();
          setRedirect(true);
          setAdminVerified(false);
          return navigate("/login");
        } else if (res.status && usr.is_admin === 0) {
          setAdminVerified(false);
          return navigate("/admin/login");
        } else if (res.status && usr.is_verified === 0) {
          setToVerify(true);
          setAdminVerified(false);
          setVerifyData({ ...verify_data, is_logged: true, user: usr.email });
          return navigate("/verify-account", { state: verify_data });
        } else if (res.status && usr.is_verified === 1) {
          setAdminVerified(true);
        }
      },
      (error) => {
        AuthService.logout();
        return navigate("/login");
      }
    );
  }, []);

  const goVerify = () => {
    return navigate("/verify-account");
  };
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const doLogout = () => {
    AuthService.logout();
    setTimeout(() => {
      console.log("Session Cleared...");
      return navigate("/");
    }, 0);
  };
  if (admin_verified) {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: "1 1",
          width: "100%",
          minHeight: "100vh",
          padding: "0 !important",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CssBaseline />
        <AdminHeader
          open={open}
          toggleDrawer={toggleDrawer}
          DrawerHeader={DrawerHeader}
          doLogout={doLogout}
        />

        <AdminSidePanel
          onopen={open}
          onclose={handleDrawerClose}
          DrawerHeader={DrawerHeader}
          doLogout={doLogout}
        />
        <main style={{ width: "100%", height: "100%" }}>
          <DrawerHeader />
          <Routes>
            <Route path="/start" element={<Start />}></Route>
            <Route path="/test" element={<Test />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/scores/*" element={<Scores />}></Route>
            <Route path="/leaderboard/*" element={<LeaderBoard />}></Route>
            <Route path="/messages/*" element={<Messages />}></Route>
            <Route path="/settings" element={<Settings />}></Route>
            <Route
              path="*"
              element={
                <>
                  <AdminHeader />
                  <Four0Four />
                </>
              }
            />
          </Routes>
        </main>
      </Box>
    );
  } else {
    return (
      <React.Fragment>
        <section className="page-main">
          <div className="flex flex-column flex-col align-items-center py20">
            <h2>We cannot verify your admin status at this point.</h2>
            <div className="pxy20">
              <Button onClick={goVerify} variant="contained" color="primary">
                Verify Account
              </Button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
