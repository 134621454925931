import React from "react";
import AuthService from "../../../services/AuthService";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Leaderboard, ScoreOutlined, Start } from "@mui/icons-material";

const usr = AuthService.getCurrentUser();

const Dashboard = () => {
  const [opts, setOpts] = React.useState<any>({
    balance: usr.balance,
    total_accounts: usr.total_accounts,
    deposits: usr.deposits,
    cards: usr.cards,
  });
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="z-high">
          <div className="home-get-grid z-high">
            <Grid container spacing={3.5}>
              <Grid item xs={12} sm={6} md={4}>
                <Link className="grid-card g1" to="/account/start">
                  <div className="icon-pane">
                    <i className="fas fa-play"></i>
                  </div>
                  <div className="pane-title">Start Exam</div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Link className="grid-card g2" to="/account/study">
                  <div className="icon-pane">
                    <i className="fas fa-book"></i>
                  </div>
                  <div className="pane-title">Study Past Questions</div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Link className="grid-card g3" to="/account/scores">
                  <div className="icon-pane">
                    <i className="fas fa-list"></i>
                  </div>
                  <div className="pane-title">Scores</div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Link className="grid-card g4" to="/account/leaderboard">
                  <div className="icon-pane">
                    <i className="fas fa-bar-chart"></i>
                  </div>
                  <div className="pane-title">LeaderBoard</div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Link className="grid-card g5" to="/account/forum">
                  <div className="icon-pane">
                    <i className="fas fa-users"></i>
                  </div>
                  <div className="pane-title">Forum</div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Link className="grid-card g6" to="/account/settings">
                  <div className="icon-pane">
                    <i className="fas fa-cogs"></i>
                  </div>
                  <div className="pane-title">Account Settings</div>
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
