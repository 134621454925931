import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ScoresList = (props: any) => {
  const [scores, setScores] = React.useState<any[]>([]);
  const [limit, setLimit] = React.useState<number>(10);
  const [offset, setOffset] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetchScores();
  }, []);

  const fetchScores = () => {
    HttpService.postHeader("scores", {
      limit: limit,
      offset: offset,
      mode: "all_scores",
    })
      .then(
        (resp) => {
          console.log("scores_response:::", resp);
          if (Array.isArray(resp)) {
            setScores(resp);
          }
        },
        (error) => {
          setScores([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //getScores
  };

  return (
    <React.Fragment>
      <section className="pxy20">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Test</StyledTableCell>
                <StyledTableCell align="center">Year</StyledTableCell>
                <StyledTableCell align="center">Passed</StyledTableCell>
                <StyledTableCell align="center">Failed</StyledTableCell>
                <StyledTableCell align="center">skipped</StyledTableCell>
                <StyledTableCell align="center">%</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scores.map((item: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    <h3>
                      <Link to={`/admin/scores/v/${item.exam_id}`}>
                        {item.exam_title}
                      </Link>
                    </h3>
                    <span className="date-span">{item.test_environment}</span>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.exam_year}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.passed}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.failed}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.skipped}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item.perc}%</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </React.Fragment>
  );
};

export default ScoresList;
