import React from "react";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import { NavLink } from "react-router-dom";
import LiveTv from "@mui/icons-material/LiveTv";
import { PhoneAndroid } from "@mui/icons-material";
import { Button, ButtonGroup } from "@mui/material";

const HomeIntro = (props: any) => {
  const { redir } = props;
  const { decodeHtml, truncateWord } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [services, setServices] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    listservices(offset);
  }, []);

  const listservices = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ offset: offset, limit: 12, mode: "all" }, "services")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (Array.isArray(result)) {
            let newRes = [...services, ...result];
            setServices(newRes);
          } else {
            setServices([]);
          }
        },
        (error) => {
          setServices([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(false);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listservices(newOffset);
  };

  return (
    <React.Fragment>
      {" "}
      <section className="home-main">
        <div className="text-detail-pane justify-content-center align-items-center">
          <div className="text-description home-text w-60-resp text-center">
            <div className="intro-logo">
              <img src={`/images/logo2.png`} alt="Cyprex Logo" />
            </div>
            <h1>Welcome to Cyprex CBT</h1>
            <p>
              Easy to use practice software with over 40 years of past questions
              & answers on WAEC, NECO and JAMB examinations.
            </p>
            <p>
              Create an account or Login to your account now to experience it!
            </p>
          </div>
        </div>
        <div className="home-nav-pane">
          <div className="cta-centered">
            <ButtonGroup>
              <Button
                variant="outlined"
                size="large"
                onClick={() => redir("/register")}
              >
                Create Account
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={() => redir("/login")}
              >
                Sign In
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default React.memo(HomeIntro);
