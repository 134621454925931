import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import StartStudy from "./StartStudy";
import StudyPage from "./StudyPage";
const Study = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/account/news/a/new`);
  };
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<StartStudy />} />
        <Route path="/" element={<StartStudy />} />
        <Route path="/s/:sessionId" element={<StudyPage />} />
      </Routes>
    </React.Fragment>
  );
};

export default Study;
