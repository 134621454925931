import React from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import LoginForm from "../Login/LoginForm";
import RegisterForm from "../Register/RegisterForm";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import CustomToast from "../../templates/CustomToast";
import RegisterModule from "../Register/RegisterModule";
const HomeRegister = (props: any) => {
  const navigate = useNavigate();
  const { redir } = props;
  const [ref_data, setRefData] = React.useState<any>({ is_ref: false });
  let params = useParams();
  const location = useLocation();
  const [btn_disabled, setDisabled] = React.useState(false);
  const [loading_text, setLoadingText] = React.useState("");

  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState(false);
  const pageName = location.pathname;
  console.log(params);
  React.useEffect(() => {
    const isParam = params.ref_id ? true : false;
    setParam(isParam);
    if (isParam) {
      getRef(params.ref_id);
    }
  }, []); //componentDidMount

  const getRef = (rid: any) => {
    console.log(rid);
    HttpService.postForm("r/fetch_ref", { ref_id: rid }).then((resp) => {
      console.log("Fetched :: ", resp);
      if (resp.status === 1 && resp.data) {
        let rd = resp.data;
        rd.is_ref = true;
        setRefData(rd);
      }
    });
  };

  const [load, setLoad] = React.useState<any>({
    action: "register",
  });

  const handleInputChange = (event: any, index: number = -1) => {
    const target = event.target;
    const value = target.value;
    var targ_name = target.name;
    console.log("required:", target.required);
    setLoad((prevState: any) => ({
      ...prevState,
      [targ_name]: value,
    }));
  };

  const [cur_page, setPage] = React.useState<number>(0);

  const stages: any[] = [
    "Personal Details",
    "Login Details",
    "Preview & Submit",
  ];

  let preview: any = [];

  function capStr(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  for (let key in load) {
    let val = load[key];
    const ko = key;
    if (key === "password") {
      val = "**********";
    } else if (key === "is_ref" && load[key] === "1") {
      val = "Yes";
    } else if (key === "is_ref" && load[key] === "0") {
      val = "No";
    }
    key = key === "is_ref" ? "Were you referred?" : key;
    key = key === "ref_id" ? "Referral Code" : key;
    key = key.replace(/nok/g, "next of kin");
    const spl = key.split("_");
    const ky = capStr(spl.join(" "));
    const op = `<div class="flex flex-row pb15"><span class="bolder">
    ${ky}</span> <span class="spacer"></span> <span>${val}
    </span></div>`;
    if (ko !== "password2" && ko !== "action") {
      preview.push(op);
    }
  }

  const closeToast = () => {
    setToast({ ...toast, onopen: false });
  };

  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: closeToast,
  });

  const handleSubmit = () => {
    setLoading(true);
    setLoaded(false);
    setToast({
      ...toast,
      onopen: true,
      message: "Sending...",
      onclose: closeToast,
    });
    setDisabled(true);
    HttpService.postHeader("register", load)
      .then(
        (response) => {
          console.log(response);
          setToast({
            ...toast,
            onopen: true,
            message: response.message,
            onclose: closeToast,
          });
          if (response.status === 1) {
            let jwt = response.jwt;
            let expire_at = response.expireAt;
            let usr = JSON.stringify(response.user);
            localStorage.setItem("user", usr);
            localStorage.setItem("access_token", jwt);
            localStorage.setItem("expire_at", expire_at);
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
              console.log("Redirecting now...");
              return navigate("/account/dashboard");
            }, 2000);
          } else if (response.status === 0) {
            setDisabled(false);
          }
          //setSnackOpen(true);
          /*  */
        },
        (error) => {
          console.log(error);
          setToast({
            ...toast,
            onopen: true,
            message: error.message,
            onclose: closeToast,
          });
          //setSnackOpen(true);
        }
      )
      .finally(() => {
        setLoaded(true);
        setLoading(false);
        setDisabled(false);
      });
  }; ///reg

  return (
    <React.Fragment>
      <section className="home-main flex-row-resp">
        <div className="text-detail-pane">
          <div className="text-description home-text w-80-resp">
            <div className="intro-logo">
              <a href="/">
                <img src={`/images/logo2.png`} alt="Cyprex Logo" />
              </a>
            </div>
            <h2>Create New Account</h2>
            <p>
              You need to use animation instead of transition. Transition
              effects are triggered on certain events, for example a click which
              adds a class or a hover.{" "}
            </p>
          </div>
        </div>
        <div className="form-pane">
          <div className="content-placer">
            <div className="flex flex-col flex-column justify-content-center align-items-center py20">
              <div className="login-pane ">
                <div className="pxy20">
                  <RegisterModule />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal data={toast} />
    </React.Fragment>
  );
};

export default React.memo(HomeRegister);
