import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import { DefaultEditor } from "react-simple-wysiwyg";

const HomeStart = (props: any) => {
  console.log("New News Renders");
  let val = props.data;

  let navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [categories_fetched, setCategoriesFetched] =
    React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<string>("");
  const [loading_text, setText] = React.useState<string>("Save Now");
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const [meta, setMeta] = React.useState<any>(new FormData());

  React.useEffect(() => {
    if (!categories_fetched) {
      fetchCats();
    }
  }, []);

  const fetchCats = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("news", { mode: "list-categories" })
      .then(
        (resp) => {
          console.log(resp);
          if (Array.isArray(resp)) {
            setCategories(resp);
            setCategoriesFetched(true);
          } else {
            setCategories([]);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onHtmlChange = (e: any) => {
    setContent(e.target.value);
    console.log(content);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("content", content);
    formData.append("mode", "create");
    formData.append("file", file);
    formData.append("title", meta.title);
    formData.append("cat_id", meta.cat_id);
    const obj: any = { ...meta, content: content, mode: "create" };
    console.log(obj);
    setLoading(true);
    setLoaded(false);
    HttpService.postForm("news", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
              navigate(`/admin/news/p/${resp.url}`);
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [file, setFile] = React.useState<any>(null);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    //setMeta({ ...meta, file: formData });
    setFile(flx);
    console.log(flx);
    //var reader = new FileReader();
    //var url = reader.readAsDataURL(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
  };
  return (
    <React.Fragment>
      <section className="flex">
        <div className="text-description">
          <div className="login-pane">
            <h3 className="py0 my0 px0 mx0">
              <i className="fas fa-newspaper"></i> Add Article
            </h3>
            <div className="spacer"></div>
            <div></div>
          </div>
          <Divider />

          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              padding: "10px",
              width: "100%",
            }}
          >
            <Link to="/admin/news">Articles</Link>

            <span>Add Article</span>
          </Breadcrumbs>
          <div className="px10 pb10">
            <Card sx={{ p: "0", m: "0" }}>
              <div style={{ padding: "20px" }}>
                <div className={loading ? " input iconed " : " input "}>
                  <label>Article Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    onChange={handleInputChange}
                    placeholder={"News Title "}
                  />
                  {loading && (
                    <span className="input-icon">
                      <i className="fas fa-refresh fa-spin"></i>
                    </span>
                  )}
                </div>
                <div className="input">
                  <label>Select Category</label>
                  <select
                    name="cat_id"
                    className="form-control"
                    onChange={handleInputChange}
                  >
                    <option value="">Select Category</option>
                    {categories.map((item: any, i: number) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="banner-section">
                  {preview_image && (
                    <div className="image_preview">
                      <img
                        className=""
                        src={preview_image}
                        alt="preview Image"
                      />
                    </div>
                  )}
                  <div className={loading ? " input iconed " : " input "}>
                    <label>News Banner Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="file"
                      onChange={handleFileChange}
                      placeholder={"News Title "}
                    />
                  </div>
                </div>
                <div className="mb10">
                  <DefaultEditor
                    className="form-control"
                    value={content}
                    placeholder="News Content"
                    onChange={onHtmlChange}
                  />
                </div>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? "Working..." : " Save "}
                </Button>
                {/**/}{" "}
              </div>
            </Card>
          </div>
        </div>
        <CustomModal data={toast} />
      </section>
    </React.Fragment>
  );
};

export default HomeStart;
