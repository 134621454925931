import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import HomeIntro from "./HomeIntro";
import HomeLogin from "./HomeLogin";
import HomeRegister from "./HomeRegister";
import "./Home.css";
import HomeStart from "./HomeStart";
const Home = () => {
  const navigate = useNavigate();

  const goTo = (route: any) => {
    return navigate(route);
  };
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<HomeIntro redir={goTo} />} />
        <Route path="/login" element={<HomeLogin redir={goTo} />} />
        <Route path="/register" element={<HomeRegister redir={goTo} />} />
        <Route path="/start" element={<HomeStart redir={goTo} />} />
      </Routes>
    </React.Fragment>
  );
};

export default Home;
