import React from "react";
import {
  CheckCircleOutline,
  CircleOutlined,
  CloseOutlined,
  DoneAll,
} from "@mui/icons-material";
import * as processHtml from "../../services/processHtml";
import { MathJax } from "better-react-mathjax";

const OptionTab = ({
  option_string,
  test_ended,
  cur_item,
  click_handle,
  load_page,
  state,
  activeStep,
}: {
  option_string: string;
  test_ended: boolean;
  cur_item: any;
  click_handle: any;
  load_page: any;
  state: any;
  activeStep: number;
}) => {
  const { decodeHtml } = processHtml;
  const opta = `option_${option_string}`;
  return (
    <React.Fragment>
      <button
        disabled={test_ended}
        className={
          cur_item.option_selected === option_string
            ? `option-container bordered active`
            : `option-container bordered`
        }
        onClick={() =>
          click_handle(
            cur_item,
            option_string,
            load_page[activeStep],
            load_page[activeStep]
          )
        }
      >
        <span className="alpha-span">{option_string.toUpperCase()}</span>
        <div className="align-items-center flex">
          <span className="check-span">
            {cur_item.option_selected &&
            cur_item.option_selected === option_string ? (
              <CheckCircleOutline sx={{ color: "#000000" }} />
            ) : (
              <CircleOutlined />
            )}
          </span>
          <MathJax>
            <div
              className="spacer text-left"
              dangerouslySetInnerHTML={{
                __html: decodeHtml(cur_item[opta]),
              }}
            ></div>
          </MathJax>
        </div>
        {cur_item.attempted && (
          <span className="check-span">
            {state.test_environment === "practice" &&
            cur_item.attempted &&
            cur_item.correct_answer === option_string ? (
              <DoneAll
                sx={{
                  color: "rgb(86, 136, 94)",
                }}
              />
            ) : (
              <CloseOutlined color="error" />
            )}
          </span>
        )}
      </button>
    </React.Fragment>
  );
};

export default OptionTab;
