import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Edit, RefreshOutlined } from "@mui/icons-material";

const PickMenu = (props: any) => {
  //console.log(props);
  let {
    list_mode,
    options,
    menu_title,
    option_click_handler,
    picked,
    max_pick,
    picked_handler,
    disabled,
    reset_handler,
  } = props;
  //console.log("picked::", picked, "max_pick", max_pick);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if (list_mode === "picker") {
      picked = [];
    }
  }, []);

  React.useEffect(() => {
    if (picked && picked.length >= max_pick) {
      setAnchorEl(null);
    }
  }, [picked]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    item: any,
    index: number
  ) => {
    setSelectedIndex(index);
    option_click_handler(item);
    setAnchorEl(null);
  };

  const handlePickedClick = (item: any, index: number) => {
    picked_handler(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canDisable = disabled && reset_handler ? true : false;

  return (
    <>
      <div className="pick-cover">
        <ListItem
          button
          divider
          disabled={canDisable}
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label={menu_title}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          {options.length > 0 ? (
            <ListItemText
              primary={<h3>{menu_title}</h3>}
              secondary={<strong>{options[selectedIndex]["title"]}</strong>}
            />
          ) : (
            <ListItemText
              primary={<h3>{"Nothing to show"}</h3>}
              secondary={<strong>Empty List</strong>}
            />
          )}
          {!canDisable && (open ? <ExpandLess /> : <ExpandMore />)} {/**/}
        </ListItem>
        {/* {disabled &&
          reset_handler &&
          (
             <span className="pick-reset">
            <a onClick={reset_handler()}>
              <RefreshOutlined />
            </a>
          </span> 
          )}*/}
      </div>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {list_mode !== "picker" &&
          options.map((option: any, index: number) => (
            <MenuItem
              key={option.title + index}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, option, index)}
            >
              {option.title}
            </MenuItem>
          ))}

        {list_mode === "picker" &&
          options.map((option: any, index: number) => (
            <ListItem divider key={option.title + index} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={() => handlePickedClick(option, index)}
                dense
                disabled={picked && picked.length >= max_pick}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={picked.indexOf(option) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": option._id + index }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={option._id + index}
                  primary={`${option.title}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </Menu>
    </>
  );
};

export default React.memo(PickMenu);
