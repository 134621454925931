import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HttpService from "../../services/HttpService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { ButtonGroup, CircularProgress, Divider, Tooltip } from "@mui/material";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  CheckCircleOutline,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  CircleOutlined,
  CloseOutlined,
  DoneAll,
  ErrorOutlineRounded,
  PowerOffOutlined,
  Send,
} from "@mui/icons-material";
import Timer from "../templates/Timer";
import * as processHtml from "../../services/processHtml";
import { MathJaxContext, MathJax } from "better-react-mathjax";
import ConfirmModal from "../templates/ConfirmModal";
import CustomModal from "../templates/CustomModal";
import OptionTab from "./OptionTab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type Anchor = "top" | "left" | "bottom" | "right";
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Test = (props: any) => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  //console.log("LOC:: ", location);
  //console.log("Test Rerenders:: ");
  const { decodeHtml, secondsToTime } = processHtml;
  const pgi = [0, 0, 0, 0];
  const [loading, setLoading] = React.useState<boolean>(false);
  const [exam_fetched, setExamFetched] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [btn_disabled, setDisabled] = React.useState(false);
  const [exam_load, setExamLoad] = React.useState<any[]>([]);
  const [duration, setDuration] = React.useState<number>(0);
  const [load_page, setLoadPage] = React.useState<any[]>(pgi);
  const [activeStep, setActiveStep] = React.useState(0);
  const [question_loaded, setQuestionLoaded] = React.useState(false);
  const [test_ended, setTestEnded] = React.useState(false);
  const jumpPage = (index: number) => {
    const newr = [...load_page];
    newr[activeStep] = index;
    setLoadPage(newr);
    toggleDrawerClose();
  };
  const nextPage = () => {
    const newr = [...load_page];
    newr[activeStep] = newr[activeStep] + 1;
    setLoadPage(newr);
  };
  const prevPage = () => {
    const newr = [...load_page];
    newr[activeStep] = newr[activeStep] - 1;
    setLoadPage(newr);
  };

  const [isParam, setParam] = React.useState<any>(false);

  const [cur_item, setCurItem] = React.useState<any>({});
  React.useEffect(() => {
    if (exam_load.length > 0 && exam_load[activeStep]["question"].length > 0) {
      /* console.log(
        "activeStep, load_page::::::",
        activeStep,
        load_page[activeStep],
        exam_load[activeStep]
      ); */
      setCurItem(exam_load[activeStep]["question"][load_page[activeStep]]);
      setQuestionLoaded(true);
    }
  }, [activeStep, load_page, exam_load]); /* */

  React.useEffect(() => {
    console.log(":::params::", state);
    if (!state) {
      //navigate("/account/start", { state: null });
    }
    const isParam = state?.exam_body ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchExam();
    }
    return () => {
      if (state?.hide_sections) {
        //navigate(location.pathname, {});
      }
    };
  }, []);

  React.useEffect(() => {
    if (exam_fetched && state?.test_duration) {
      //console.log("state.test_duration");
      setDuration(state?.test_duration * 60);
      setCurDur(state?.test_duration * 60);
    }
  }, [duration, exam_fetched, state?.test_duration]);

  const fetchExam = () => {
    setLoading(true);
    setLoaded(false);

    HttpService.postHeader("test_page", { mode: "start_test", data: state })
      .then(
        (resp) => {
          console.log("start_response::", resp);
          if (Array.isArray(resp)) {
            setExamLoad(resp);
            setExamFetched(true);
            if (resp.length > 0 && resp[activeStep]["question"].length > 0) {
              setCurItem(
                exam_load[activeStep]["question"][load_page[activeStep]]
              );
            } else {
              setExamLoad([]);
            }
          } else {
            setExamLoad([]);
          }
        },
        (error) => {
          setExamLoad([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //
  };

  const closeToast = () => {
    setToast({ ...toast, onopen: false });
  };
  const closeConfirm = () => {
    setConfirm({ ...toast, onopen: false });
  };

  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: closeToast,
  });
  const [confirm, setConfirm] = React.useState<any>({
    onopen: false,
    onclose: closeConfirm,
  });

  const handleTerminate = (data: any = false) => {
    const tm =
      data && data.rem_time && data.rem_time > 60 ? "minutes" : "seconds";
    const mess =
      data && data.rem_time && data.rem_time > 0
        ? `Are you sure you want to stop and submit this test?
      You still have <u>${secondsToTime(data.rem_time)}</u> more ${tm}.`
        : `Are you sure you want to stop and submit this test?`;

    setConfirm({
      ...confirm,
      onopen: true,
      message: mess,
      onaccept: () => handleSubmit(),
      onclose: closeConfirm,
      title: "Are you sure?",
    });
  }; //

  const handleEnd = () => {
    setToast({
      ...toast,
      onopen: true,
      message: "Your time has expired. Submitting test now...",
      onclose: closeToast,
      title: "Time has ran out",
    });
    handleSubmit();
  }; //
  const handleSubmit = () => {
    setTestEnded(true);
    closeConfirm();
    setToast({
      ...toast,
      onopen: true,
      onclose: closeToast,
      message: "Submitting test now...",
      title: "Submit Test",
    });
    setTimeout(() => {
      submitExam();
    }, 3000);
  };

  const submitExam = () => {
    console.log("SUBMITTEDEXAMDATA::", exam_load);
    setLoading(true);
    setLoaded(false);

    HttpService.postHeader("save_test", {
      test_item: exam_load,
      test_meta: state,
      mode: "submit_test",
    })
      .then(
        (resp) => {
          console.log("submit_test_response:::", resp);
          setToast({
            ...toast,
            onopen: true,
            message: resp.message,
            onclose: closeToast,
          });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false, onclose: closeToast });
              navigate(`/account/scores/v/${resp.exam_id}`);
            }, 6000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const handleStep = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };
  /**/
  const handleAnswerChange = React.useCallback(
    (item: any, a: string, i: number, p: number) => {
      //console.log(a, i);
      let newArr = [...exam_load];
      newArr[activeStep]["question"][i]["option_selected"] = a;
      newArr[activeStep]["question"][i]["attempted"] = true;
      setExamLoad(newArr); /**/
      //console.log(exam_load[activeStep]["question"][i]);
    },
    [exam_load, load_page, activeStep]
  ); /* */

  const [drawer_open, setDrawer] = React.useState(false);
  const [preview, setPreview] = React.useState<any[]>([]);
  const toggleDrawerClose = () => {
    setDrawer(false);
    setPreview([]);
  };
  const toggleDrawer = () => {
    setDrawer(!drawer_open);
    //if (drawer_open) {
    setPreview(exam_load[activeStep]["question"]);
    //}
  };
  const [current_duration, setCurDur] = React.useState<number>(0);
  const handleFeedBack = (dur: number) => {
    setCurDur(dur);
    state.left_duration = dur;
  };
  return (
    <React.Fragment>
      {exam_fetched && !question_loaded && (
        <div className="flex flex-column flex-col  text-center align-items-center py20">
          <span className="pxy20 text-center">
            <ErrorOutlineRounded sx={{ fontSize: "70px" }} />
          </span>
          <h2 className="text-center txt-lg mb20">Error Loading Question. </h2>{" "}
          <h3>Please Select other exam year or subjects(s) </h3>
          <div className="py30">
            <Link to={`/account/start`}>
              <Button variant="outlined" size="large">
                Go Back
              </Button>
            </Link>
          </div>
        </div>
      )}
      <MathJaxContext>
        {exam_fetched && question_loaded && (
          <section className="full-page">
            <Tabs
              value={activeStep}
              variant="scrollable"
              scrollButtons
              onChange={handleStep}
              aria-label="subjects tab"
            >
              {state.picked_subjects.map((item: any, index: number) => (
                <Tab
                  key={item.title}
                  label={item.title}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            <Divider />

            {exam_load[activeStep]["question"] &&
              exam_load[activeStep]["question"].length <= 0 && (
                <div className="full-page">
                  <div className="abs-centered">
                    <div className="load-error-icon">
                      <ErrorOutlineRounded
                        fontSize="large"
                        sx={{ fontSize: "3.5em" }}
                      />
                    </div>
                    <h2>
                      No questions found for{" "}
                      <u>{state.picked_subjects[activeStep].title}.</u>
                    </h2>
                  </div>
                </div>
              )}

            {exam_load[activeStep]["question"] &&
              exam_load[activeStep]["question"].length > 0 && (
                <>
                  <div className="flex flex-row align-items-center px20  py10">
                    <span className="spacer">--</span>
                    <span className="spacer text-center">
                      <span className="timer-span">
                        {exam_load[activeStep]["question"] && (
                          <Timer
                            seconds={state.test_duration}
                            end_handle={handleEnd}
                            feed_back={handleFeedBack}
                            environment={state.test_environment}
                          />
                        )}
                      </span>
                    </span>
                    <span className="spacer text-right">
                      <span className="px0">
                        <ButtonGroup>
                          {load_page[activeStep] > 0 && (
                            <Tooltip title="Back to previous question">
                              <Button
                                disabled={btn_disabled}
                                onClick={() => prevPage()}
                              >
                                <ChevronLeftOutlined />
                                <span className="sm-hide"> Previous</span>
                              </Button>
                            </Tooltip>
                          )}
                          {load_page[activeStep] <
                            exam_load[activeStep]["question"].length - 1 && (
                            <Tooltip title="Move to next question">
                              <Button
                                disabled={btn_disabled}
                                onClick={() => nextPage()}
                              >
                                <span className="sm-hide">Next </span>
                                <ChevronRightOutlined />
                              </Button>
                            </Tooltip>
                          )}
                        </ButtonGroup>
                      </span>
                    </span>
                  </div>
                  {/**NAV Ends */}
                  <TabPanel value={activeStep} index={activeStep}>
                    <Slide
                      direction="right"
                      in={load_page[activeStep] === cur_item.index}
                    >
                      <section className="card">
                        <span className="num-text">
                          {load_page[activeStep] + 1}
                        </span>
                        <div className="ml20 pb10 flex flex-col spacer">
                          <div className="mb10 question_pane">
                            {cur_item.photo && (
                              <div className="question-photo">
                                <img
                                  src={`${process.env.REACT_APP_SERVER_FILES_DOMAIN}files/test/question/${cur_item.photo}`}
                                  alt="question-picture"
                                />
                              </div>
                            )}
                            <MathJax>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: decodeHtml(cur_item["question"]),
                                }}
                              ></div>
                            </MathJax>
                          </div>
                          <div className="answer-container">
                            <OptionTab
                              option_string="a"
                              test_ended={test_ended}
                              cur_item={cur_item}
                              click_handle={handleAnswerChange}
                              load_page={load_page}
                              state={state}
                              activeStep={activeStep}
                            />
                            <OptionTab
                              option_string="b"
                              test_ended={test_ended}
                              cur_item={cur_item}
                              click_handle={handleAnswerChange}
                              load_page={load_page}
                              state={state}
                              activeStep={activeStep}
                            />
                            <OptionTab
                              option_string="c"
                              test_ended={test_ended}
                              cur_item={cur_item}
                              click_handle={handleAnswerChange}
                              load_page={load_page}
                              state={state}
                              activeStep={activeStep}
                            />
                            <OptionTab
                              option_string="d"
                              test_ended={test_ended}
                              cur_item={cur_item}
                              click_handle={handleAnswerChange}
                              load_page={load_page}
                              state={state}
                              activeStep={activeStep}
                            />
                            {cur_item.option_e && (
                              <OptionTab
                                option_string="e"
                                test_ended={test_ended}
                                cur_item={cur_item}
                                click_handle={handleAnswerChange}
                                load_page={load_page}
                                state={state}
                                activeStep={activeStep}
                              />
                            )}
                            <div className="pxy20"></div>
                          </div>
                        </div>
                      </section>
                    </Slide>
                  </TabPanel>

                  <div className="footer-action-bar">
                    <span className="spacer">
                      <ButtonGroup>
                        {state.left_duration <= 0 && (
                          <Tooltip title="Terminate and Submit Test">
                            <Button
                              onClick={handleSubmit}
                              variant="contained"
                              size="large"
                              color="primary"
                              disabled={!test_ended || state.left_duration > 0}
                            >
                              <span className="sm-hide">SUBMIT TEST </span>{" "}
                              <Send />
                            </Button>
                          </Tooltip>
                        )}
                        {state.left_duration > 0 && (
                          <Tooltip title="Terminate and Submit Test">
                            <Button
                              onClick={() =>
                                handleTerminate({
                                  rem_time: state.left_duration,
                                })
                              }
                              variant="outlined"
                              size="large"
                              color="error"
                            >
                              <span className="sm-hide">End & Submit </span>
                              <PowerOffOutlined />
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </span>
                    <span className="text-center">
                      <Tooltip title="Jump to question">
                        <Button
                          onClick={toggleDrawer}
                          color="primary"
                          size="large"
                        >
                          <span className="sm-hide">JUMP TO QUESTION </span>{" "}
                          {drawer_open ? (
                            <ArrowDropDownOutlined />
                          ) : (
                            <ArrowDropUpOutlined />
                          )}
                        </Button>
                      </Tooltip>
                    </span>
                    <span className=" spacer text-right">
                      <ButtonGroup>
                        {load_page[activeStep] > 0 && (
                          <Tooltip title="Back to previous question">
                            <Button
                              disabled={btn_disabled}
                              onClick={() => prevPage()}
                            >
                              <ChevronLeftOutlined />
                              <span className="sm-hide"> Previous</span>
                            </Button>
                          </Tooltip>
                        )}
                        {load_page[activeStep] <
                          exam_load[activeStep]["question"].length - 1 && (
                          <Tooltip title="Move to next question">
                            <Button
                              disabled={btn_disabled}
                              onClick={() => nextPage()}
                            >
                              <span className="sm-hide">Next </span>
                              <ChevronRightOutlined />
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </span>
                  </div>
                </>
              )}
            {exam_load[activeStep]["question"] &&
              exam_load[activeStep]["question"].length > 0 && (
                <Drawer
                  anchor={`bottom`}
                  open={drawer_open}
                  onClose={toggleDrawerClose}
                >
                  <div className="flex flex-column justify-content-center align-items-center">
                    <Tooltip title="Close Panel">
                      <Button
                        onClick={toggleDrawer}
                        color="primary"
                        size="large"
                      >
                        <span className="sm-hide">CLOSE </span>{" "}
                        {drawer_open ? (
                          <ArrowDropDownOutlined />
                        ) : (
                          <ArrowDropUpOutlined />
                        )}
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="prev-container">
                    {preview.map((item: any, index: number) => (
                      <a
                        onClick={() => jumpPage(index)}
                        key={index}
                        className={item.attempted ? "active" : "not"}
                      >
                        {index + 1}
                        {item.option_selected && (
                          <span className="o-sel">{`${item.option_selected}`}</span>
                        )}
                      </a>
                    ))}
                  </div>
                </Drawer>
              )}
          </section>
        )}
        {!exam_fetched && (
          <div className="full-page">
            <div className="abs-centered">
              <div>
                <CircularProgress size={50} />
              </div>
              <h2>Loading Test...</h2>
            </div>
          </div>
        )}
      </MathJaxContext>
      <ConfirmModal data={confirm} />
      <CustomModal data={toast} />
    </React.Fragment>
  );
};

export default Test;
