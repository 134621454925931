import {
  AccountCircleOutlined,
  BackupOutlined,
  CameraAltOutlined,
  ChevronRightOutlined,
  LogoutOutlined,
  MultilineChartOutlined,
  Password,
  SettingsAccessibility,
  TableChartOutlined,
  Timer,
  VolumeMuteOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined,
} from "@mui/icons-material";
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slider,
  Avatar,
  Switch,
  Icon,
  IconButton,
} from "@mui/material";
import React from "react";
import CustomComponent from "../templates/CustomComponent";

const Settings = () => {
  const [vals, setVals] = React.useState<any>({
    backup: true,
    rank: true,
    sound: false,
    timer: 20,
  });

  const handleChange = React.useCallback(
    (e: any, key: string) => {
      console.log(e.target.checked);
      setVals({ ...vals, [key]: e.target.checked });
      console.log(vals);
    },
    [vals]
  );
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
  });

  const launchComponent = (type: number) => {
    setModal({ ...modal, onopen: true, type: type });
  };

  return (
    <React.Fragment>
      <section className="pxy20">
        <div className="avatar-pane flex flex-column justify-content-center pxy10">
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 136, height: 136 }}
          >
            <IconButton onClick={() => launchComponent(2)}>
              <CameraAltOutlined />
            </IconButton>
          </Avatar>
        </div>

        <h3>Account Settings</h3>
        <Card sx={{ m: "5px 0 20px 0" }}>
          <List sx={{ p: "0", m: "0" }}>
            <ListItem
              divider
              secondaryAction={
                <Switch onChange={(e) => handleChange(e, "backup")} />
              }
            >
              <ListItemIcon>
                <BackupOutlined />
              </ListItemIcon>
              <ListItemText primary={<>Back Up Scores to Web</>}></ListItemText>
            </ListItem>
            <ListItem
              divider
              secondaryAction={
                <Switch onChange={(e) => handleChange(e, "rank")} />
              }
            >
              <ListItemIcon>
                <MultilineChartOutlined />
              </ListItemIcon>
              <ListItemText
                primary={<>List me on Leaderboard</>}
              ></ListItemText>
            </ListItem>
            <ListItem
              divider
              secondaryAction={
                <Switch onChange={(e) => handleChange(e, "sound")} />
              }
            >
              <ListItemIcon>
                {vals.sound ? (
                  <VolumeUpOutlined color="primary" />
                ) : (
                  <VolumeOffOutlined />
                )}
              </ListItemIcon>
              <ListItemText primary={<>Turn on sounds</>}></ListItemText>
            </ListItem>
            <ListItem
              divider
              secondaryAction={
                <Slider step={1} min={2} max={10} orientation="horizontal" />
              }
            >
              <ListItemIcon>
                <Timer />
              </ListItemIcon>
              <ListItemText
                primary={<>Show timer reminder at:</>}
              ></ListItemText>
            </ListItem>
          </List>
        </Card>
        <h3>Profile Settings</h3>
        <Card sx={{ m: "5px 0 20px 0" }}>
          <List sx={{ p: "0", m: "0" }}>
            <ListItem
              divider
              disablePadding
              secondaryAction={<ChevronRightOutlined />}
            >
              <ListItemButton onClick={() => launchComponent(0)}>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={<>Edit Details</>}></ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              divider
              disablePadding
              secondaryAction={<ChevronRightOutlined />}
            >
              <ListItemButton onClick={() => launchComponent(1)}>
                <ListItemIcon>
                  <Password />
                </ListItemIcon>
                <ListItemText primary={<>Change Password</>}></ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              divider
              disablePadding
              secondaryAction={<ChevronRightOutlined />}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LogoutOutlined color="error" />
                </ListItemIcon>
                <ListItemText primary={<>Log Out</>}></ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Card>
      </section>
      <CustomComponent data={modal} />
    </React.Fragment>
  );
};
export default Settings;
