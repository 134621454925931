import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HttpService from "../../services/HttpService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { ButtonGroup, Divider, Tooltip } from "@mui/material";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  CheckCircleOutline,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  CircleOutlined,
  CloseOutlined,
  DoneAll,
  ErrorOutlineRounded,
  PowerOffOutlined,
  Send,
} from "@mui/icons-material";
import Timer from "../templates/Timer";
import * as processHtml from "../../services/processHtml";
import { MathJaxContext, MathJax } from "better-react-mathjax";
import ConfirmModal from "../templates/ConfirmModal";
import CustomModal from "../templates/CustomModal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type Anchor = "top" | "left" | "bottom" | "right";
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Test = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("LOC:: ", location);
  console.log("Test Rerenders:: ");
  const { decodeHtml, secondsToTime } = processHtml;
  const pgi = [0, 0, 0, 0];
  const [loading, setLoading] = React.useState<boolean>(false);
  const [exam_fetched, setExamFetched] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [btn_disabled, setDisabled] = React.useState(false);
  const [exam_load, setExamLoad] = React.useState<any[]>([]);
  const [duration, setDuration] = React.useState<number>(0);
  const [load_page, setLoadPage] = React.useState<any[]>(pgi);
  const [activeStep, setActiveStep] = React.useState(0);
  const [question_loaded, setQuestionLoaded] = React.useState(false);
  const [test_ended, setTestEnded] = React.useState(false);
  const jumpPage = (index: number) => {
    const newr = [...load_page];
    newr[activeStep] = index;
    setLoadPage(newr);
    toggleDrawerClose();
  };
  const nextPage = () => {
    const newr = [...load_page];
    newr[activeStep] = newr[activeStep] + 1;
    setLoadPage(newr);
  };
  const prevPage = () => {
    const newr = [...load_page];
    newr[activeStep] = newr[activeStep] - 1;
    setLoadPage(newr);
  };

  const state = location.state;

  const [isParam, setParam] = React.useState<any>(false);

  const [cur_item, setCurItem] = React.useState<any>({});
  React.useEffect(() => {
    if (exam_load.length > 0 && exam_load[activeStep]["question"].length > 0) {
      console.log(
        "activeStep, load_page::::::",
        activeStep,
        load_page[activeStep],
        exam_load[activeStep]
      );
      setCurItem(exam_load[activeStep]["question"][load_page[activeStep]]);
      setQuestionLoaded(true);
    }
  }, [activeStep, load_page, exam_load]); /* */

  React.useEffect(() => {
    console.log(":::params::", state);
    const isParam = state.exam_body ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchExam();
    }
  }, []);

  React.useEffect(() => {
    if (exam_fetched && state.test_duration) {
      console.log("state.test_duration");
      setDuration(state.test_duration * 60);
      setCurDur(state.test_duration * 60);
    }
  }, [duration, exam_fetched, state.test_duration]);

  const fetchExam = () => {
    setLoading(true);
    setLoaded(false);

    HttpService.postHeader("test", { mode: "start_test", data: state })
      .then(
        (resp) => {
          console.log("::", resp);
          if (Array.isArray(resp)) {
            setExamLoad(resp);
            setExamFetched(true);
            if (resp.length > 0 && resp[activeStep]["question"].length > 0) {
              setCurItem(
                exam_load[activeStep]["question"][load_page[activeStep]]
              );
            } else {
              setExamLoad([]);
            }
          } else {
            setExamLoad([]);
          }
        },
        (error) => {
          setExamLoad([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //
  };

  const closeToast = () => {
    setToast({ ...toast, onopen: false });
  };
  const closeConfirm = () => {
    setConfirm({ ...toast, onopen: false });
  };

  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: closeToast,
  });
  const [confirm, setConfirm] = React.useState<any>({
    onopen: false,
    onclose: closeConfirm,
  });

  const handleTerminate = (data: any = false) => {
    const tm =
      data && data.rem_time && data.rem_time > 60 ? "minutes" : "seconds";
    const mess =
      data && data.rem_time && data.rem_time > 0
        ? `Are you sure you want to stop and submit this test?
      You still have <u>${secondsToTime(data.rem_time)}</u> more ${tm}.`
        : `Are you sure you want to stop and submit this test?`;

    setConfirm({
      ...confirm,
      onopen: true,
      message: mess,
      onaccept: () => handleSubmit(),
      onclose: closeConfirm,
      title: "Are you sure?",
    });
  }; //

  const handleEnd = () => {
    setToast({
      ...toast,
      onopen: true,
      message: "Your time has expired. Submitting test now",
      onclose: closeToast,
      title: "Time has ran out",
    });
    handleSubmit();
  }; //
  const handleSubmit = () => {
    setTestEnded(true);
    closeConfirm();
    setToast({
      ...toast,
      onopen: true,
      onclose: closeToast,
      message: "Submitting test now...",
      title: "Submit Test",
    });
    setTimeout(() => {
      submitExam();
    }, 3000);
  };

  const submitExam = () => {
    console.log("SDATA:::::::::::::::", exam_load);
    setLoading(true);
    setLoaded(false);

    HttpService.postHeader("test", {
      test_item: exam_load,
      test_meta: state,
      mode: "submit_test",
    })
      .then(
        (resp) => {
          console.log("submit_test_response:::", resp);
          setToast({
            ...toast,
            onopen: true,
            message: resp.message,
            onclose: closeToast,
          });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false, onclose: closeToast });
              navigate(`/admin/scores/v/${resp.exam_id}`);
            }, 6000);
          }
        },
        (error) => {
          //setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const handleStep = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };
  /**/
  const handleAnswerChange = React.useCallback(
    (item: any, a: string, i: number, p: number) => {
      console.log(a, i);
      let newArr = [...exam_load];
      newArr[activeStep]["question"][i]["option_selected"] = a;
      newArr[activeStep]["question"][i]["attempted"] = true;
      setExamLoad(newArr); /**/

      console.log(exam_load[activeStep]["question"][i]);
    },
    [exam_load, load_page, activeStep]
  ); /* */

  const [drawer_open, setDrawer] = React.useState(false);
  const [preview, setPreview] = React.useState<any[]>([]);
  const toggleDrawerClose = () => {
    setDrawer(false);
    setPreview([]);
  };
  const toggleDrawer = () => {
    setDrawer(!drawer_open);
    //if (drawer_open) {
    setPreview(exam_load[activeStep]["question"]);
    //}
  };
  const [current_duration, setCurDur] = React.useState<number>(0);
  const handleFeedBack = (dur: number) => {
    setCurDur(dur);
    state.left_duration = dur;
  };
  return (
    <React.Fragment key={`bottom`}>
      <MathJaxContext>
        <MathJax>
          {!exam_fetched ||
            (!question_loaded && (
              <div className="load-error">
                <span className="load-error-icon">
                  <ErrorOutlineRounded fontSize="large" />
                </span>
                <h3>
                  {" "}
                  Error Loading Question. Please Select other exam year or
                  subjects(s){" "}
                </h3>
              </div>
            ))}
          {exam_fetched && question_loaded && (
            <>
              <section className="px0">
                <Box sx={{ width: "100%" }}>
                  <div className="bg-white pxy0">
                    <Tabs
                      value={activeStep}
                      variant="scrollable"
                      scrollButtons
                      onChange={handleStep}
                      aria-label="basic tabs example"
                    >
                      {state.picked_subjects.map((item: any, index: number) => (
                        <Tab
                          key={item.title}
                          label={item.title}
                          {...a11yProps(index)}
                        />
                      ))}
                    </Tabs>
                  </div>
                  <Divider />

                  {exam_load[activeStep]["question"] &&
                    exam_load[activeStep]["question"].length <= 0 && (
                      <div className="load-error">
                        <span className="load-error-icon">
                          <ErrorOutlineRounded
                            fontSize="large"
                            sx={{ fontSize: "2em" }}
                          />
                        </span>
                        <h3>
                          No questions found for{" "}
                          <u>{state.picked_subjects[activeStep].title}.</u>
                        </h3>
                      </div>
                    )}

                  {exam_load[activeStep]["question"] &&
                    exam_load[activeStep]["question"].length > 0 && (
                      <>
                        <div className="flex flex-row align-items-center px20  py10">
                          <span className="spacer">--</span>

                          <span className="timer-span  text-center">
                            {exam_load[activeStep]["question"] && (
                              <Timer
                                seconds={state.test_duration}
                                end_handle={handleEnd}
                                feed_back={handleFeedBack}
                                environment={state.test_environment}
                              />
                            )}
                          </span>
                          <span className="spacer"></span>
                          <span>
                            <ButtonGroup>
                              {load_page[activeStep] > 0 && (
                                <Tooltip title="Back to previous question">
                                  <Button
                                    disabled={btn_disabled}
                                    onClick={() => prevPage()}
                                  >
                                    <ChevronLeftOutlined />
                                    <span className="sm-hide"> Previous</span>
                                  </Button>
                                </Tooltip>
                              )}
                              {load_page[activeStep] <
                                exam_load[activeStep]["question"].length -
                                  1 && (
                                <Tooltip title="Move to next question">
                                  <Button
                                    disabled={btn_disabled}
                                    onClick={() => nextPage()}
                                  >
                                    <span className="sm-hide">Next </span>
                                    <ChevronRightOutlined />
                                  </Button>
                                </Tooltip>
                              )}
                            </ButtonGroup>
                          </span>
                        </div>
                        {/**NAV Ends */}
                        <TabPanel value={activeStep} index={activeStep}>
                          <Slide
                            direction="right"
                            in={load_page[activeStep] === cur_item.index}
                          >
                            <div className="px20 pb10">
                              <Card sx={{ p: "0" }}>
                                <div className="pxy20">
                                  <section>
                                    <div className="py10 ">
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        <span className="num-text">
                                          {load_page[activeStep] + 1}
                                        </span>
                                        <div className="pl20 pb10 flex flex-grow spacer">
                                          <section>
                                            <div className="mb10 question_pane">
                                              {cur_item.photo && (
                                                <div className="question-photo">
                                                  <img
                                                    src={`${process.env.REACT_APP_SERVER_FILES_DOMAIN}files/test/question/${cur_item.photo}`}
                                                    alt="question-picture"
                                                  />
                                                </div>
                                              )}

                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: decodeHtml(
                                                    cur_item["question"]
                                                  ),
                                                }}
                                              ></div>
                                            </div>
                                            <section className="answer-container">
                                              <button
                                                disabled={test_ended}
                                                className={
                                                  cur_item.option_selected ===
                                                  "a"
                                                    ? `option-container active`
                                                    : `option-container`
                                                }
                                                onClick={() =>
                                                  handleAnswerChange(
                                                    cur_item,
                                                    "a",
                                                    load_page[activeStep],
                                                    load_page[activeStep]
                                                  )
                                                }
                                              >
                                                <span className="alpha-span">
                                                  A
                                                </span>
                                                <span className="check-span">
                                                  {cur_item.option_selected &&
                                                  cur_item.option_selected ===
                                                    "a" ? (
                                                    <CheckCircleOutline
                                                      sx={{ color: "#0a0483" }}
                                                    />
                                                  ) : (
                                                    <CircleOutlined />
                                                  )}
                                                </span>
                                                <div
                                                  className="spacer text-left"
                                                  dangerouslySetInnerHTML={{
                                                    __html: decodeHtml(
                                                      cur_item.option_a
                                                    ),
                                                  }}
                                                ></div>
                                                {cur_item.attempted && (
                                                  <span className="check-span">
                                                    {state.test_environment ===
                                                      "practice" &&
                                                    cur_item.attempted &&
                                                    cur_item.correct_answer ===
                                                      "a" ? (
                                                      <DoneAll
                                                        sx={{
                                                          color:
                                                            "rgb(86, 136, 94)",
                                                        }}
                                                      />
                                                    ) : (
                                                      <CloseOutlined color="error" />
                                                    )}
                                                  </span>
                                                )}
                                              </button>{" "}
                                              <button
                                                disabled={test_ended}
                                                className={
                                                  cur_item.option_selected ===
                                                  "b"
                                                    ? `option-container active`
                                                    : `option-container`
                                                }
                                                onClick={() =>
                                                  handleAnswerChange(
                                                    cur_item,
                                                    "b",
                                                    load_page[activeStep],
                                                    load_page[activeStep]
                                                  )
                                                }
                                              >
                                                <span className="alpha-span">
                                                  B
                                                </span>
                                                <span className="check-span">
                                                  {cur_item.option_selected ===
                                                  "b" ? (
                                                    <CheckCircleOutline
                                                      sx={{ color: "#0a0483" }}
                                                    />
                                                  ) : (
                                                    <CircleOutlined />
                                                  )}
                                                </span>
                                                <div
                                                  className="spacer text-left"
                                                  dangerouslySetInnerHTML={{
                                                    __html: decodeHtml(
                                                      cur_item.option_b
                                                    ),
                                                  }}
                                                ></div>
                                                {cur_item.attempted && (
                                                  <span className="check-span">
                                                    {state.test_environment ===
                                                      "practice" &&
                                                    cur_item.attempted &&
                                                    cur_item.correct_answer ===
                                                      "b" ? (
                                                      <DoneAll
                                                        sx={{
                                                          color:
                                                            "rgb(86, 136, 94)",
                                                        }}
                                                      />
                                                    ) : (
                                                      <CloseOutlined color="error" />
                                                    )}
                                                  </span>
                                                )}
                                              </button>
                                              <button
                                                disabled={test_ended}
                                                className={
                                                  cur_item.option_selected ===
                                                  "c"
                                                    ? `option-container active`
                                                    : `option-container`
                                                }
                                                onClick={() =>
                                                  handleAnswerChange(
                                                    cur_item,
                                                    "c",
                                                    load_page[activeStep],
                                                    load_page[activeStep]
                                                  )
                                                }
                                              >
                                                <span className="alpha-span">
                                                  C
                                                </span>
                                                <span className="check-span">
                                                  {cur_item.option_selected ===
                                                  "c" ? (
                                                    <CheckCircleOutline
                                                      sx={{ color: "#0a0483" }}
                                                    />
                                                  ) : (
                                                    <CircleOutlined />
                                                  )}
                                                </span>
                                                <div
                                                  className="spacer text-left"
                                                  dangerouslySetInnerHTML={{
                                                    __html: decodeHtml(
                                                      cur_item.option_c
                                                    ),
                                                  }}
                                                ></div>
                                                {cur_item.attempted && (
                                                  <span className="check-span">
                                                    {state.test_environment ===
                                                      "practice" &&
                                                    cur_item.correct_answer ===
                                                      "c" ? (
                                                      <DoneAll
                                                        sx={{
                                                          color:
                                                            "rgb(86, 136, 94)",
                                                        }}
                                                      />
                                                    ) : (
                                                      <CloseOutlined color="error" />
                                                    )}
                                                  </span>
                                                )}
                                              </button>
                                              <button
                                                disabled={test_ended}
                                                className={
                                                  cur_item.option_selected ===
                                                  "d"
                                                    ? `option-container active`
                                                    : `option-container`
                                                }
                                                onClick={() =>
                                                  handleAnswerChange(
                                                    cur_item,
                                                    "d",
                                                    load_page[activeStep],
                                                    load_page[activeStep]
                                                  )
                                                }
                                              >
                                                <span className="alpha-span">
                                                  D
                                                </span>
                                                <span className="check-span">
                                                  {cur_item.option_selected ===
                                                  "d" ? (
                                                    <CheckCircleOutline
                                                      sx={{ color: "#0a0483" }}
                                                    />
                                                  ) : (
                                                    <CircleOutlined />
                                                  )}
                                                </span>
                                                <div
                                                  className="spacer text-left"
                                                  dangerouslySetInnerHTML={{
                                                    __html: decodeHtml(
                                                      cur_item.option_d
                                                    ),
                                                  }}
                                                ></div>
                                                {cur_item.attempted && (
                                                  <span className="check-span">
                                                    {state.test_environment ===
                                                      "practice" &&
                                                    cur_item.correct_answer ===
                                                      "d" ? (
                                                      <DoneAll
                                                        sx={{
                                                          color:
                                                            "rgb(86, 136, 94)",
                                                        }}
                                                      />
                                                    ) : (
                                                      <CloseOutlined color="error" />
                                                    )}
                                                  </span>
                                                )}
                                              </button>
                                              {cur_item.option_e && (
                                                <button
                                                  disabled={test_ended}
                                                  className={
                                                    cur_item.option_selected ===
                                                    "e"
                                                      ? `option-container active`
                                                      : `option-container`
                                                  }
                                                  onClick={() =>
                                                    handleAnswerChange(
                                                      cur_item,
                                                      "e",
                                                      load_page[activeStep],
                                                      load_page[activeStep]
                                                    )
                                                  }
                                                >
                                                  <span className="alpha-span">
                                                    E
                                                  </span>
                                                  <span className="check-span">
                                                    {cur_item.option_selected ===
                                                    "e" ? (
                                                      <CheckCircleOutline
                                                        sx={{
                                                          color: "#0a0483",
                                                        }}
                                                      />
                                                    ) : (
                                                      <CircleOutlined />
                                                    )}
                                                  </span>
                                                  <div
                                                    className="spacer text-left"
                                                    dangerouslySetInnerHTML={{
                                                      __html: decodeHtml(
                                                        cur_item.option_e
                                                      ),
                                                    }}
                                                  ></div>
                                                  {cur_item.attempted && (
                                                    <span className="check-span">
                                                      {state.test_environment ===
                                                        "practice" &&
                                                      cur_item.correct_answer ===
                                                        "e" ? (
                                                        <DoneAll
                                                          sx={{
                                                            color:
                                                              "rgb(86, 136, 94)",
                                                          }}
                                                        />
                                                      ) : (
                                                        <CloseOutlined color="error" />
                                                      )}
                                                    </span>
                                                  )}
                                                </button>
                                              )}
                                            </section>
                                          </section>
                                        </div>
                                      </Box>
                                    </div>
                                  </section>
                                </div>
                              </Card>
                            </div>
                          </Slide>
                        </TabPanel>

                        <div className="flex flex-row align-items-center px20">
                          <ButtonGroup>
                            {state.left_duration <= 0 && (
                              <Tooltip title="Terminate and Submit Test">
                                <Button
                                  onClick={handleSubmit}
                                  variant="contained"
                                  size="large"
                                  color="primary"
                                  disabled={
                                    !test_ended || state.left_duration > 0
                                  }
                                >
                                  <span className="sm-hide">SUBMIT TEST </span>{" "}
                                  <Send />
                                </Button>
                              </Tooltip>
                            )}
                            {state.left_duration > 0 && (
                              <Tooltip title="Terminate and Submit Test">
                                <Button
                                  onClick={() =>
                                    handleTerminate({
                                      rem_time: state.left_duration,
                                    })
                                  }
                                  variant="outlined"
                                  size="large"
                                  color="error"
                                >
                                  {" "}
                                  <span className="sm-hide">End & Submit </span>
                                  <PowerOffOutlined />
                                </Button>
                              </Tooltip>
                            )}
                          </ButtonGroup>
                          <span className="spacer text-center">
                            <Tooltip title="Jump to question">
                              <Button
                                onClick={toggleDrawer}
                                color="primary"
                                size="large"
                              >
                                <span className="sm-hide">
                                  JUMP TO QUESTION{" "}
                                </span>{" "}
                                {drawer_open ? (
                                  <ArrowDropDownOutlined />
                                ) : (
                                  <ArrowDropUpOutlined />
                                )}
                              </Button>
                            </Tooltip>
                          </span>
                          <ButtonGroup>
                            {load_page[activeStep] > 0 && (
                              <Tooltip title="Back to previous question">
                                <Button
                                  disabled={btn_disabled}
                                  onClick={() => prevPage()}
                                >
                                  <ChevronLeftOutlined />
                                  <span className="sm-hide"> Previous</span>
                                </Button>
                              </Tooltip>
                            )}
                            {load_page[activeStep] <
                              exam_load[activeStep]["question"].length - 1 && (
                              <Tooltip title="Move to next question">
                                <Button
                                  disabled={btn_disabled}
                                  onClick={() => nextPage()}
                                >
                                  <span className="sm-hide">Next </span>
                                  <ChevronRightOutlined />
                                </Button>
                              </Tooltip>
                            )}
                          </ButtonGroup>
                        </div>
                      </>
                    )}
                </Box>
              </section>
              {exam_load[activeStep]["question"] &&
                exam_load[activeStep]["question"].length > 0 && (
                  <Drawer
                    anchor={`bottom`}
                    open={drawer_open}
                    onClose={toggleDrawerClose}
                  >
                    <div className="flex flex-column justify-content-center align-items-center">
                      <Tooltip title="Close Panel">
                        <Button
                          onClick={toggleDrawer}
                          color="primary"
                          size="large"
                        >
                          <span className="sm-hide">CLOSE </span>{" "}
                          {drawer_open ? (
                            <ArrowDropDownOutlined />
                          ) : (
                            <ArrowDropUpOutlined />
                          )}
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="prev-container">
                      {preview.map((item: any, index: number) => (
                        <a
                          onClick={() => jumpPage(index)}
                          key={index}
                          className={item.attempted ? "active" : "not"}
                        >
                          {index + 1}
                          {item.option_selected && (
                            <span className="o-sel">{`${item.option_selected}`}</span>
                          )}
                        </a>
                      ))}
                    </div>
                  </Drawer>
                )}
            </>
          )}
          {!exam_fetched && <>Loading Test...</>}
        </MathJax>
      </MathJaxContext>
      <ConfirmModal data={confirm} />
      <CustomModal data={toast} />
    </React.Fragment>
  );
};

export default Test;
