import React from "react";
import AuthService from "../../../services/AuthService";
import { Link } from "react-router-dom";
import { LiveTvOutlined } from "@mui/icons-material";
const usr = AuthService.getCurrentUser();
const Dashboard = () => {
  const [opts, setOpts] = React.useState<any>({
    balance: usr.balance,
    total_accounts: usr.total_accounts,
    deposits: usr.deposits,
    cards: usr.cards,
  });
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="z-high">
          <div className="home-get-grid z-high">
            <Link className="grid-card g1" to="/admin/go-live">
              <div className="icon-pane">
                <LiveTvOutlined />
              </div>
              <div className="pane-title">Go Live</div>
            </Link>
            <Link className="grid-card g2" to="/admin/programs">
              <div className="icon-pane">
                <i className="fas fa-list"></i>
              </div>
              <div className="pane-title">Programs</div>
            </Link>

            <Link className="grid-card g3" to="/admin/news">
              <div className="icon-pane">
                <i className="fas fa-rss"></i>
              </div>
              <div className="pane-title">News</div>
            </Link>

            <Link className="grid-card g4" to="/admin/gallery">
              <div className="icon-pane">
                <i className="fas fa-image"></i>
              </div>
              <div className="pane-title">Gallery</div>
            </Link>

            <Link className="grid-card g7" to="/admin/staff">
              <div className="icon-pane">
                <i className="fas fa-user-tie"></i>
              </div>
              <div className="pane-title">Staff</div>
            </Link>

            <Link className="grid-card g5" to="/admin/messages">
              <div className="icon-pane">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="pane-title">Messages</div>
            </Link>

            <Link className="grid-card g6" to="/admin/settings">
              <div className="icon-pane">
                <i className="fas fa-cogs"></i>
              </div>
              <div className="pane-title">Settings</div>
            </Link>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
