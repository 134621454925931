import React from "react";
import { Link, NavLink } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import Avatar from "@mui/material/Avatar";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Settings from "@mui/icons-material/Settings";
import MailOutline from "@mui/icons-material/MailOutline";
import List from "@mui/material/List";
import {
  Dashboard,
  GroupWorkOutlined,
  PlayLesson,
  ReadMoreOutlined,
  ScoreOutlined,
  ScoreboardOutlined,
  SettingsOutlined,
  TableChartOutlined,
} from "@mui/icons-material";

const drawerWidth = 240;
const AccountHeader = ({
  togNav,
  location,
  usr,
  doLogout,
}: {
  togNav: any;
  location: any;
  usr: any;
  doLogout: any;
}) => {
  const pages: any[] = [
    { path: "dashboard", component: "Dashboard", icon: <Dashboard /> },
    { path: "start", component: "Start Exam", icon: <PlayLesson /> },
    { path: "scores", component: "My Scores", icon: <TableChartOutlined /> },
    { path: "study", component: "Study Questions", icon: <ReadMoreOutlined /> },
    {
      path: "leaderboard",
      component: "Leaderboard",
      icon: <GroupWorkOutlined />,
    },
    {
      path: "settings",
      component: "Profile Settings",
      icon: <SettingsOutlined />,
    },
  ];
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <React.Fragment>
      <div className="whitepaper-header-container">
        <span className="mobile-ham">
          <a onClick={togNav}>
            <i className="fas fa-bars"></i>
          </a>
        </span>
        <span className="ham-logo">
          <a href="/">
            <img src="/images/logo.png" alt="Logo" />
          </a>
        </span>
        <span className="spacer"></span>
        <Box
          sx={{
            px: "20px",
            flexGrow: 0,
            m: "0",
            display: {
              xs: "inline-flex",
              sm: "inline-flex",
              md: "inline-flex",
            },
          }}
        >
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Menu"
                sx={{ border: "2px solid #fff" }}
                src={
                  usr && usr.avatar
                    ? `${process.env.REACT_APP_SERVER_DOMAIN + usr.avatar}`
                    : `/images/avatar.jpg`
                }
              />
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ m: "0", p: "0 !important" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            onClick={handleCloseUserMenu}
          >
            {/*  <Paper sx={{ width: "auto", maxWidth: "100%" }}> */}
            <List sx={{ paddingTop: "0", paddingBottom: "0", m: "0" }}>
              {pages.map((item: any, index: number) => (
                <MenuItem key={index} sx={{ p: "0", m: "0" }}>
                  <ListItem
                    disablePadding
                    button
                    key={item.id}
                    sx={{ margin: "0px" }}
                    component={NavLink}
                    to={item.path === "/" ? "../" : `${item.path}`}
                  >
                    <ListItemButton>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>{item.component}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </MenuItem>
              ))}
            </List>
            <Divider />
            <MenuItem>
              <List sx={{ m: "0", p: "0" }}>
                <ListItem disablePadding onClick={doLogout}>
                  <ListItemIcon>
                    <LogoutOutlined />
                  </ListItemIcon>
                  <ListItemText></ListItemText>
                  <Typography variant="body2" color="text.primary">
                    Log Out
                  </Typography>
                </ListItem>
              </List>
            </MenuItem>
            {/* </Paper> */}
          </Menu>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default AccountHeader;
