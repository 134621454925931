import * as React from "react";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AccountHeader from "./AccountHeader";
import Dashboard from "./Dashboard/Dashboard";
import Four0Four from "../public/Four0Four/Four0Four";
import AuthService from "../../services/AuthService";
import HttpService from "../../services/HttpService";
import Messages from "./Messages/Messages";
import Start from "./Start/Start";
import Test from "../Test/Test";
import Scores from "./Scores/Scores";
import Settings from "./Settings/Settings";
import LeaderBoard from "./LeaderBoard";
import { CircularProgress, LinearProgress } from "@mui/material";
import AccountSidePanel from "./AccountSidePanel";
import "../dashboard.css";
import "../skeleton.css";
import Study from "./Study/Study";

export default function Account() {
  console.log("Account page Renders");
  const [redirect, setRedirect] = React.useState(false);
  const [to_verify, setToVerify] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [resending, setResending] = React.useState(false);
  const [user_verified, setUserVerified] = React.useState(false);
  const [loading_text, setLoadingText] = React.useState(
    "Please enter the verification code sent your phone or email"
  );
  const [verify_data, setVerifyData] = React.useState<any>({});
  const [checked_session, setChecked] = React.useState<boolean>(false);
  const usr = AuthService.getCurrentUser();
  let navigate = useNavigate();
  let location = useLocation();
  const [hide_sections, setHideSections] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (location?.state?.hide_sections) {
      setHideSections(true);
    }
  }, [location]);
  React.useEffect(() => {
    AuthService.checkSession().then(
      (res) => {
        setChecked(true);
        console.log("SESSION CHECK RESULT:: ", res);
        /*   if (!res.status || res.status === false) {
          AuthService.logout();
          setRedirect(true);
          setUserVerified(false);
          return navigate("/login");
        } else if (res.status && usr.is_verified === 0) {
          setToVerify(true);
          setVerifyData({ ...verify_data, user: usr.email });
          return navigate("/verify-account", { state: verify_data });
          setUserVerified(false);
        } else if (res.status && usr.is_verified === 1) {
          setUserVerified(true);
        } */
        if (res.status) {
          setUserVerified(true);
        }
      },
      (error) => {
        AuthService.logout();
        return navigate("/login");
      }
    );
  }, []);

  const goVerify = () => {
    return navigate("/verify-account");
  };
  const doLogout = () => {
    AuthService.logout();
    setTimeout(() => {
      console.log("Session Cleared...");
      return navigate("/");
    }, 300);
  };

  //const [isLogged, setIsLogged] = React.useState(logx);
  const [cur_slug, setSlug] = React.useState<any | string>(null);

  const page = useLocation()["pathname"];
  const pgarr = page.split("/");
  console.log(page, pgarr);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (pgarr.length == 4) {
      setSlug(pgarr[3]);
    }
  }, []);

  const [togged, setTogged] = React.useState<boolean>(false);
  const togNav = () => {
    setTogged(!togged);
  };
  if (checked_session && user_verified) {
    return (
      <React.Fragment>
        <section className="white-main">
          <AccountHeader
            togNav={togNav}
            location={location}
            usr={usr}
            doLogout={doLogout}
          />

          <section className="whitepaper-body-container">
            {/* <AccountSidePanel
              togged={togged}
              togNav={togNav}
              setSlug={setSlug}
              cur_slug={cur_slug}
              doLogout={doLogout}
            />
            
            <div
              className={`whitepaper-side-nav ${togged ? "expand-width" : ""}`}
            >
              <ul className="whitepaper-list">
                {pages.map((page) => (
                  <li key={page.path}>
                    <Link
                      to={`/account/${page.path}`}
                      className={cur_slug === page.path ? "active" : ""}
                      onClick={() => {
                        setSlug(page.path);
                        togNav();
                      }}
                    >
                      {page.icon} &nbsp; {page.title}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    to={`null`}
                    className={`logout`}
                    onClick={() => {
                      doLogout();
                    }}
                  >
                    <LogoutOutlined /> &nbsp; Log Out
                  </Link>
                </li>
              </ul>
                  </div>*/}

            <div className="whitepaper-page-content">
              <div className="card-content">
                <div className="container">
                  <Routes>
                    <Route path="/start" element={<Start />}></Route>
                    <Route path="/test" element={<Test />}></Route>
                    <Route path="/dashboard" element={<Dashboard />}></Route>
                    <Route path="/scores/*" element={<Scores />}></Route>
                    <Route path="/study/*" element={<Study />}></Route>
                    <Route
                      path="/leaderboard/*"
                      element={<LeaderBoard />}
                    ></Route>
                    <Route path="/messages/*" element={<Messages />}></Route>
                    <Route
                      path="/settings"
                      element={<Settings user={usr} />}
                    ></Route>
                    <Route path="*" element={<Four0Four />} />
                  </Routes>
                </div>
              </div>
            </div>
          </section>
        </section>
      </React.Fragment>
    );
  } else if (checked_session && !user_verified) {
    return (
      <React.Fragment>
        <AccountHeader
          togNav={togNav}
          location={location}
          usr={usr}
          doLogout={doLogout}
        />
        <section className="page-main">
          <div className=" py20">
            <h2>We cannot verify your registration status at this point.</h2>
            <div className="pxy20">
              <Button onClick={goVerify} variant="contained" color="primary">
                Verify Account
              </Button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <AccountHeader
          togNav={togNav}
          location={location}
          usr={usr}
          doLogout={doLogout}
        />
        <section className="page-main">
          <div className="flex flex-column flex-col align-items-center py20">
            <div className="pxy20">
              <CircularProgress size={50} />
            </div>
            <h2>Checking session....</h2>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
