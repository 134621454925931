import {
  PauseOutlined,
  PlayArrowOutlined,
  SettingsOverscanTwoTone,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import CustomModal from "./CustomModal";

function padTime(t: number) {
  return t < 10 ? "0" + t : t;
}

const secondsToTime = (_seconds: any) => {
  if (typeof _seconds !== "number" || _seconds < 0) return "00:00:00";
  var hours = Math.floor(_seconds / 3600),
    minutes = Math.floor((_seconds % 3600) / 60),
    seconds = Math.floor(_seconds % 60);

  return padTime(hours) + ":" + padTime(minutes) + ":" + padTime(seconds);
};

const Timer = (props: any) => {
  //le.log("Timer::", props);
  const { seconds, feed_back, environment, end_handle } = props;
  const [duration, setDuration] = React.useState<number>(seconds * 60);
  const [paused, setPaused] = React.useState<boolean>(false);
  const [time_ran_out, setTimeRanOut] = React.useState<boolean>(false);
  const closeToast = () => setToast({ ...toast, onopen: false });
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: closeToast,
  });
  const setLeft = (dur: any) => {
    setDuration((prev) => prev - 1);
  };

  React.useEffect(() => {
    doTimer();
  }, [duration, paused]); /* */
  const [reminded, setReminded] = React.useState<boolean>(false);
  const doTimer = () => {
    if (!paused) {
      if (duration <= 60 && !reminded) {
        setToast({
          ...toast,
          onopen: true,
          onclose: closeToast,
          message: "<h1>You have less than 1 minute left</h1>",
          hideduration: 4000,
          hideTitle: true,
        });
        setReminded(true);
      }
      if (duration < 1) {
        setTimeRanOut(true);
        if (end_handle) end_handle({ rem_time: duration });
        return;
      }
      const timer = setTimeout(() => {
        setLeft(duration);
        feed_back(duration);
      }, 1000);
      return () => clearTimeout(timer);
    }
  };

  const pause_action =
    /*React.useCallback(*/
    (mode: string) => {
      if (mode === "start") {
        setPaused(false);
      } else if (mode === "pause") {
        setPaused(true);
      }
    };
  /*,
    [paused]
  );*/
  return (
    <React.Fragment>
      <span className="flex flex-row align-items-center">
        <span className="timer-span">{secondsToTime(duration)}</span>
        {!time_ran_out && environment === "practice" && (
          <>
            {paused ? (
              <Tooltip title="Resume timer">
                <IconButton
                  color="primary"
                  onClick={() => pause_action("start")}
                >
                  <PlayArrowOutlined />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Pause timer">
                <IconButton color="error" onClick={() => pause_action("pause")}>
                  <PauseOutlined />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </span>
      <CustomModal data={toast} />
    </React.Fragment>
  );
};
export default React.memo(Timer);
