import React from "react";
import { Link, NavLink } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import LoginForm from "../Login/LoginForm";
import LoginModule from "../Login/LoginModule";
const HomeLogin = (props: any) => {
  const { redir } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [response_text, setResponseText] = React.useState<string>("");
  const modalClose = () => setModal({ ...modal_data, onopen: false });
  const [modal_data, setModal] = React.useState<any>({
    onopen: false,
    onclose: modalClose,
  });
  const doLogin = (data: any) => {
    data.action = "doLogin";
    console.log(data);
    setLoading(true);
    setLoaded(false);
    setResponseText("Loging in....");
    modalClose();
    setModal({
      ...modal_data,
      onopen: true,
      onclose: modalClose,
      message: "Loging in....",
    });
    HttpService.postForm("login", data)
      .then(
        (response) => {
          let rsp = response;
          console.log("::::", rsp);
          const severity =
            rsp.status === 1
              ? "success"
              : rsp.status === 0
              ? "error"
              : rsp.status === 44
              ? "error"
              : "info";
          setModal({
            ...modal_data,
            onopen: true,
            message: rsp.message,
            severity: severity,
            onclose: modalClose,
          });
          if (
            (response.jwt &&
              response.expireAt &&
              response.status === 1 &&
              response.isFound === 1) ||
            response.status === 44
          ) {
            let jwt = rsp.jwt;
            let usr = JSON.stringify(rsp.user);
            localStorage.setItem("user", usr);
            localStorage.setItem("access_token", jwt);
            setResponseText(rsp.message);

            const next_url = "/admin/dashboard";
            const redir_delay = response.status === 1 ? 5000 : 6000;
            setTimeout(() => {
              console.log("Redirecting now...");
              window.location.href = next_url;
              setModal({ ...modal_data, onopen: false });
              return;
            }, redir_delay);
          } else if (response.status === 0 && response.isFound === 1) {
            setResponseText(response.message);
          } else if (response.status === 0 && response.isFound === 0) {
            setResponseText(response.message);
          } else {
            setResponseText(response.message);
          }
        }, //resPonse ends//
        (error) => {
          setResponseText(error.message);
          setModal({
            onopen: true,
            message: error.message,
            onclose: modalClose,
            severity: "error",
          });
          setTimeout(() => {
            modalClose();
          }, 7000);
        } //error ends//
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  const items: any = { submit_handler: doLogin, loading: loading };
  return (
    <React.Fragment>
      <section className="home-main  flex-row-resp">
        <div className="text-detail-pane">
          <div className="text-description home-text w-80-resp">
            <div className="intro-logo">
              <a href="/">
                <img src={`/images/logo2.png`} alt="Cyprex Logo" />
              </a>
            </div>
            <h1>User Login</h1>
            <p>
              You need to use animation instead of transition. Transition
              effects are triggered on certain events, for example a click which
              adds a class or a hover.{" "}
            </p>
          </div>
        </div>
        <div className="form-pane">
          <div className="content-placer">
            <LoginModule />
            <div className="py20">
              Not yet registered?{" "}
              <a onClick={() => redir("/register")}>Sign Up</a>
            </div>
          </div>
        </div>
        <CustomModal data={modal_data} />
      </section>
    </React.Fragment>
  );
};

export default React.memo(HomeLogin);
