import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";

const ScoresList = (props: any) => {
  const [scores, setScores] = React.useState<any[]>([]);
  const [limit, setLimit] = React.useState<number>(10);
  const [offset, setOffset] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetchScores();
  }, []);

  const fetchScores = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("all_scores", {
      limit: limit,
      offset: offset,
      mode: "all_scores",
    })
      .then(
        (resp) => {
          console.log("scores_response:::", resp);
          if (Array.isArray(resp.data)) {
            setScores(resp.data);
          }
        },
        (error) => {
          setScores([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //getScores
  };

  return (
    <React.Fragment>
      <section className="pxy20 flex flex-col">
        <TableContainer
          component={Paper}
          sx={{ display: "flex", width: "100%" }}
        >
          <Table sx={{ minWidth: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Test</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Passed</TableCell>
                <TableCell align="center">Failed</TableCell>
                <TableCell align="center">skipped</TableCell>
                <TableCell align="center">%</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scores.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <h3>
                      <Link to={`/account/scores/v/${item.exam_id}`}>
                        {item.exam_body} <em>[{item.exam_year}]</em>
                      </Link>
                    </h3>
                    <span className="date-span">{item.test_environment}</span>
                  </TableCell>
                  <TableCell align="center">{item.total_questions}</TableCell>
                  <TableCell align="center">{item.passed}</TableCell>
                  <TableCell align="center">{item.failed}</TableCell>
                  <TableCell align="center">{item.skipped}</TableCell>
                  <TableCell align="center">{item.perc}%</TableCell>
                </TableRow>
              ))}
            
            </TableBody>
          </Table>
        </TableContainer>  {loading && (
                <div className="flex flex-column flex-col  text-center align-items-center py20">
                  <span className="pxy20 text-center">
                    <CircularProgress size={50} />
                  </span>
                  <div className="text-center txt-lg">Loading scores....</div>
                </div>
              )}
      </section>
    </React.Fragment>
  );
};

export default ScoresList;
