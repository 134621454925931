import * as React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import Paper from "@mui/material/Paper";
import {
  Button,
  Card,
  Divider,
  Icon,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid"; // Grid version 1
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid version 2
import { secondsToTime } from "../../../services/processHtml";

const ScoresDetail = (props: any) => {
  console.log("ScoresReview");
  const location = useLocation();
  let params: any = useParams();
  console.log("params", params);
  const [performance, setPerformance] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState<any>(false);

  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.testId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchDetails(params.testId);
    }
  }, []);

  const fetchDetails = (id: string) => {
    HttpService.postHeader("scores", {
      mode: "score_details",
      exam_id: id,
    })
      .then(
        (resp) => {
          console.log("scores_response:::", resp);
          setPerformance(resp);
        },
        (error) => {
          setPerformance([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //getScores
  };

  return (
    <React.Fragment>
      <section className="pxy20">
        {/* 
      <List>
        <ListItem>
          <ListItemText>
            <h2>Overall Performance</h2>
            <div className="overall">
              <span className="o-bit">
                Total Questions<h3>{performance.total}</h3>
              </span>
              <span className="o-bit">
                Total Passed <h3>{performance.passed}</h3>
              </span>
              <span className="o-bit">
                Total Failed <h3>{performance.failed}</h3>
              </span>
              <span className="o-bit">
                Total Skipped <h3>{performance.skipped}</h3>
              </span>
              <span className="o-bit">
                Total Time Spent <h3>{secondsToTime(performance.duration)}</h3>
              </span>
            </div>
          </ListItemText>
        </ListItem>

        <Divider />
        <div
          className="pad-vert-10 center"
          ng-if="limitScores < overall.length"
        >
          <a className="md-button md-primary flex" ng-click="loadMore()">
            <h3>
              Load More Scores{" "}
              <Icon>
                <i className="material-icons">keyboard_arrow_down</i>
              </Icon>{" "}
            </h3>
          </a>
        </div>
      </List> */}

        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          columns={{ xs: 1, sm: 4, md: 6 }}
        >
          {performance.map((item: any, index: number) => (
            <Grid item xs={1} sm={4} md={6} key={index}>
              <section>
                <Card>
                  <div className="score-cover">
                    <div className="py10">
                      <h3>{item.title}</h3>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Total</span>{" "}
                      <span>{item.total}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Attempted</span>{" "}
                      <span>{item.attempted}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Skipped</span>{" "}
                      <span>{item.skipped}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Passed</span>{" "}
                      <span>{item.passed}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Failed</span>{" "}
                      <span>{item.failed}</span>
                    </div>
                    <span className="score-line">
                      <span className="spacer skd">
                        <i className="fas fa-clock"></i> Duration
                      </span>{" "}
                      <span>{secondsToTime(item.durr)}</span>
                    </span>
                  </div>

                  <Divider />
                  <div>
                    <Link to={`/admin/scores/r/${item.tID},${item.exam_id}`}>
                      <Button size="large" color="primary" fullWidth>
                        <Icon>&#xE417;</Icon> &nbsp;Review Answers
                      </Button>
                    </Link>
                  </div>
                </Card>
              </section>
            </Grid>
          ))}
        </Grid>
      </section>
    </React.Fragment>
  );
};

export default ScoresDetail;
