import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";

import * as processHtml from "../../../services/processHtml";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SeoModule from "../../../services/SeoModule";
import HttpService from "../../../services/HttpService";
import Loading from "../../templates/Loading";
import { LinearProgress } from "@mui/material";

const FAQ = () => {
  let navigate = useNavigate();
  const { decodeHtml, truncateWord } = processHtml;
  const [faqs, setFaq] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    listfaqs();
  }, []);

  const listfaqs = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.getHeader("fetch_faq")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (Array.isArray(result.data)) {
            setFaq(result.data);
          } else {
            setFaq([]);
          }
        },
        (error) => {
          setFaq([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...faqs];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setFaq(mutd);
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      <SeoModule
        title="Reva - FAQ"
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/">Home</Link>
                    <span>FAQ</span>
                  </Breadcrumbs>
                  <h2 className="mt20">Frequently Asked Questions</h2>
                </div>
              </div>
            </div>

            <div className="pxy0">
              {loading && (
                <div className="pxy20">
                  <LinearProgress />
                </div>
              )}

              {loaded && (
                <div>
                  {faqs.map((item: any, index: number) => (
                    <Accordion
                      key={item.id}
                      expanded={expanded === `panel${index}`}
                      onChange={handleAccordionChange(`panel${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel${index}a-header`}
                      >
                        <div>
                          <h3>{item.question}</h3>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className="properties-summary"
                          dangerouslySetInnerHTML={{
                            __html: decodeHtml(item.answer),
                          }}
                        ></div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              )}
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default FAQ;
