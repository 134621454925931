import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import HttpService from "../../../services/HttpService";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import PickMenu from "../../templates/PickMenu";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import IconButton from "@mui/material/IconButton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

const Start = (props: any) => {
  console.log("Start Renders");
  let val = props.data;
  let navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loading_years, setLoadingYears] = React.useState<boolean>(false);
  const [subjects_fetched, setSubjectsFetched] = React.useState<boolean>(false);
  const [exam_years, setYears] = React.useState<any[]>([]);
  const [exam_years_fetched, setExamYearsFetched] =
    React.useState<boolean>(false);
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [selected_subjects, setSelected] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [years_loaded, setYearsLoaded] = React.useState<boolean>(false);
  const [exam_reset, setExamReset] = React.useState<boolean>(true);
  const rese: any = {
    hide_sections: true,
    exam_body: false,
    test_environment: false,
    subject_mode: false,
    exam_year: false,
    subjects: false,
    test_duration: 5,
    question_num: 5,
    subject_selected: false,
    max_pick: false,
  };
  const [meta, setMeta] = React.useState<any>(rese);
  const resetExam = () => {
    //setExamReset(true);
    setMeta(rese);
    setPicked([]);
  };
  const resetSubjectMode = () => {
    setMeta({ ...meta, subject_mode: false });
    setPicked([]);
  };
  React.useEffect(() => {
    if (!subjects_fetched) {
      fetchSubjects();
    }
  }, []);

  const fetchSubjects = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.getHeader("test_get_subjects")
      .then(
        (resp) => {
          if (Array.isArray(resp)) {
            setSubjects(resp);
            setToast({ ...toast, subjects: resp, onclose: onClose });
            setSubjectsFetched(true);
          } else {
            setSubjects([]);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  let exam_yrs: any[] = [];
  const fetchExamYears = (body: any) => {
    setLoadingYears(true);
    setYearsLoaded(false);
    console.log("b:", body);
    HttpService.postHeader("test_get_exam_years", {
      mode: "exam_years",
      body: body,
    })
      .then(
        (resp) => {
          console.log(resp);
          if (Array.isArray(resp)) {
            resp.unshift({ path: false, title: "None Selected" });
            setYears(resp);

            setExamYearsFetched(true);
          } else {
            setYears([]);
          }
        },
        (error) => {
          setYears([]);
        }
      )
      .finally(() => {
        setLoadingYears(false);
        setYearsLoaded(true);
      }); //postTicket
  }; //fetchExamYears

  const [qnum, setQnum] = React.useState<number>(5);
  const [tdur, setDur] = React.useState<number>(5);

  const handleNum = (event: Event, newValue: number | number[]) => {
    setQnum(newValue as number);
  };
  const handleDuration = (event: Event, newValue: number | number[]) => {
    setDur(newValue as number);
  };

  React.useEffect(() => {
    setMeta({ ...meta, question_num: qnum, test_duration: tdur });
  }, [qnum, tdur]);

  const onClose = (data: any) => {
    console.log("returned selected:: ", data);
    setToast({ onopen: false, onclose: onClose, subjects: subjects });
    setSelected(data);
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onClose,
    subjects: subjects,
    message: "",
    subject_mode: meta.subject_mode,
  });
  const removeSubject = (item: any) => {
    const index = picked_subjects.indexOf(item);
    const new_sel = [...picked_subjects];
    if (index !== -1) {
      new_sel.splice(index, 1);
      setPicked(new_sel);
    }
  };

  const resetSubjects = () => {
    setPicked([]);
    setMeta({ ...meta, subjects_selected: false, exam_years: false });
    //setToast({ ...toast, onopen: true, subjects: subjects, onclose: onClose });
  };

  const exam_bodies = [
    { path: false, title: "None Selected" },
    { path: "WAEC", title: "WAEC" },
    { path: "NECO", title: "NECO" },
    { path: "JAMB", title: "JAMB" },
  ];

  const test_environments: any[] = [
    { title: "None Selected", path: false },
    { title: "Exam Environment", path: "exam" },
    { title: "Practice Environment", path: "practice" },
  ];
  const subject_modes: any[] = [
    { title: "None Selected", path: false },
    { title: "Single Subject", path: "single" },
    { title: "Multi Subject", path: "multi" },
  ];
  const setExamBody = (item: any) => {
    console.log("ITEM: ", item);
    resetExam();
    setMeta({
      ...meta,
      exam_body: item.path,
      max_pick: item.path === "JAMB" ? 4 : 1,
    });
    fetchExamYears(item.path);
  };
  const setSubjectMode = (item: any) => {
    setMeta({
      ...meta,
      subject_mode: item.path,
      max_pick: item.path === "multi" ? 4 : 1,
    });
    setPicked([]);
  };
  const setTestEnvironment = (item: any) => {
    setMeta({
      ...meta,
      test_environment: item.path,
      test_duration: 5,
      question_num: 5,
    });
  };

  const setExamYear = (item: any) => {
    console.log(item);
    setMeta({
      ...meta,
      exam_year: item.path,
    });
  };

  const setSubjectPicked = (item: any) => {
    setMeta({ ...meta, subject_selected: item.path });
  };

  const [picked_subjects, setPicked] = React.useState<any[]>([]);
  const tmx = new Date().getTime();
  const checkCount = () => {
    if (picked_subjects.length >= 4) {
      onClose(picked_subjects);
    }
  };

  React.useEffect(() => {
    checkCount();
    setMeta({
      ...meta,
      picked_subjects: picked_subjects,
      subject_selected: picked_subjects.length > 0 ? true : false,
    });
  }, [picked_subjects]);

  const handleToggle = (item: any) => {
    checkCount();
    const index = picked_subjects.indexOf(item);
    const new_sel = [...picked_subjects];
    if (index === 0) {
      setPicked([]);
    }
    if (index !== -1) {
      new_sel.splice(index, 1);
      setPicked(new_sel);
    } else {
      new_sel.push(item);
      setPicked(new_sel);
      setMeta({ ...meta, subjects_selected: true });
    }
  };

  const handleChange = (item: any) => {
    const index = picked_subjects.indexOf(item);
    const new_sel = [...picked_subjects];
    if (index === 0) {
      setPicked([]);
    }
    if (index !== -1) {
      new_sel.splice(index, 1);
      setPicked([]);
    } else {
      new_sel.push(item);
      setPicked([item]);
      console.log(picked_subjects);
      setMeta({ ...meta, subjects_selected: true });
      toast.onclose(picked_subjects);
    }
  };

  const goTesting = () => {
    navigate("/test", { state: meta });
  };

  let preview: any = [];

  function capStr(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  for (let key in meta) {
    let val = meta[key];
    const ko = key;
    key = key.replace(/nok/g, "next of kin");
    const spl = key.split("_");
    const ky = capStr(spl.join(" "));
    const op = `<div class="flex flex-row pb15"><span class="bolder">
    ${ky}</span> <span class="spacer"></span> <span>${val}
    </span></div>`;
    if (ko !== "password2" && ko !== "action") {
      preview.push(op);
    }
  }

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      <div
        className="page-top-intro flex px10 py10 align-items-center 
      justify-content-center bg-white"
      >
        <h3 className="py0 my0 px0 mx0">
          <i className="fas fa-newspaper"></i> Start Test
        </h3>
        <div className="spacer"></div>
        <div>-</div>
      </div>
      <Divider />
      <section className="flex pxy20">
        <div className="text-description dashboard">
          <div className="px0">
            <Card sx={{ p: "0", m: "0" }}>
              <div className="start-cover">
                <div className="start-form">
                  <div className="pxy20">
                    {/*<pre>{JSON.stringify(meta)}</pre> */}

                    {exam_reset && (
                      <PickMenu
                        {...{
                          options: exam_bodies,
                          disabled: meta.exam_body ? true : false,
                          reset_handler: resetExam,
                          menu_title: "Exam Body",
                          option_click_handler: setExamBody,
                        }}
                      />
                    )}

                    {loading_years && (
                      <div className="pxy20 border-radius-5 bordered">
                        Loading years....
                      </div>
                    )}
                    {meta.exam_body && exam_years_fetched && (
                      <PickMenu
                        {...{
                          options: exam_years,
                          menu_title: "Exam Year",
                          disabled: meta.exam_year ? true : false,
                          reset_handler: resetExam,
                          option_click_handler: setExamYear,
                        }}
                      />
                    )}

                    {meta.exam_body && exam_years_fetched && meta.exam_year && (
                      <PickMenu
                        {...{
                          options: test_environments,
                          menu_title: "Test Environment",
                          disabled: meta.test_environment ? true : false,
                          reset_handler: resetExam,
                          option_click_handler: setTestEnvironment,
                        }}
                      />
                    )}

                    {meta.exam_body === "JAMB" && meta.exam_year && (
                      <div className="flex flex-row relative">
                        <div className="spacer">
                          <PickMenu
                            {...{
                              options: subject_modes,
                              menu_title: "Test Subject(s) Mode",
                              disabled: meta.subject_mode ? true : false,
                              reset_handler: resetExam,
                              option_click_handler: setSubjectMode,
                            }}
                          />
                        </div>

                        {meta.subject_mode && (
                          <span className="input-edit">
                            <Tooltip title="Change Test Type">
                              <IconButton
                                color="warning"
                                onClick={resetSubjectMode}
                              >
                                <i className="fas fa-refresh"></i>
                              </IconButton>
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    )}
                    {subjects_fetched && picked_subjects.length > 0 && (
                      <Card>
                        <ListItem divider>
                          <ListItemText
                            primary={
                              <h3 className="boldest">Selected Subjects</h3>
                            }
                          />
                        </ListItem>
                        {picked_subjects.map((item: any, index: number) => (
                          <ListItem
                            divider
                            secondaryAction={
                              <IconButton
                                onClick={() => removeSubject(item)}
                                edge="end"
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          >
                            <ListItemText
                              primary={
                                <>
                                  <span className="pr10">{index + 1}</span>
                                  {item.title}
                                </>
                              }
                            />
                          </ListItem>
                        ))}
                      </Card>
                    )}

                    {meta.exam_body &&
                      meta.test_environment &&
                      subjects_fetched &&
                      picked_subjects.length < meta.max_pick && (
                        <PickMenu
                          {...{
                            list_mode: "picker",
                            picked: picked_subjects,
                            max_pick: meta.max_pick,
                            disabled: picked_subjects.length === meta.max_pick,
                            reset_handler: true,
                            picked_handler:
                              meta.test_environment === "multi"
                                ? handleChange
                                : handleToggle,
                            options: subjects,
                            menu_title:
                              meta.test_environment === "multi"
                                ? "Subjects"
                                : "Subject",
                            option_click_handler: setSubjectPicked,
                          }}
                        />
                      )}

                    {meta.test_environment &&
                      meta.subject_selected &&
                      meta.test_environment === "practice" && (
                        <List
                          component="div"
                          aria-label="Pick Menu"
                          sx={{
                            bgcolor: "background.paper",
                            p: "0",
                            m: "0 0 10px 0",
                            display: "block",
                          }}
                        >
                          <ListItem
                            divider
                            id="lock-button"
                            aria-haspopup="listbox"
                            aria-controls="lock-menu"
                            aria-label={"Select Duration"}
                          >
                            <ListItemText
                              primary={
                                <>
                                  <h3>Number of Questions</h3>
                                  <Box sx={{ width: "100%", paddingY: "10px" }}>
                                    <Stack
                                      spacing={2}
                                      direction="row"
                                      sx={{ mb: 1 }}
                                      alignItems="center"
                                    >
                                      <Slider
                                        aria-label="nums"
                                        value={qnum}
                                        size="medium"
                                        onChange={handleNum}
                                        step={1}
                                        marks
                                        min={1}
                                        max={100}
                                      />
                                      <span className="pl5">{qnum}</span>
                                    </Stack>
                                  </Box>
                                </>
                              }
                            />
                          </ListItem>
                        </List>
                      )}

                    {meta.test_environment &&
                      meta.subject_selected &&
                      meta.test_environment === "practice" && (
                        <List
                          component="div"
                          aria-label="Pick Menu"
                          sx={{
                            bgcolor: "background.paper",
                            p: "0",
                            m: "0 0 10px 0",
                            display: "block",
                          }}
                        >
                          <ListItem
                            divider
                            id="lock-button"
                            aria-haspopup="listbox"
                            aria-controls="lock-menu"
                            aria-label={"Select Duration"}
                          >
                            <ListItemText
                              primary={
                                <>
                                  <h3>Test Duration (Minutes)</h3>
                                  <Box sx={{ width: "100%", paddingY: "10px" }}>
                                    <Stack
                                      spacing={2}
                                      direction="row"
                                      sx={{ mb: 1 }}
                                      alignItems="center"
                                    >
                                      <Slider
                                        aria-label="nums"
                                        value={tdur}
                                        size="medium"
                                        onChange={handleDuration}
                                        step={1}
                                        marks
                                        min={1}
                                        max={100}
                                      />
                                      <span className="pl5">{`${tdur}`}</span>
                                    </Stack>
                                  </Box>
                                </>
                              }
                            />
                          </ListItem>
                        </List>
                      )}

                    <div
                      className="flex py10 
                        flex-row align-items-center mb5"
                    >
                      <span className="spacer pr5">
                        {meta.test_environment &&
                          meta.exam_body &&
                          meta.subject_selected && (
                            <Button
                              type="submit"
                              size="large"
                              variant="contained"
                              disabled={loading}
                              onClick={goTesting}
                            >
                              {loading ? (
                                <span>Working...</span>
                              ) : (
                                <>
                                  Start
                                  <span className="sm-hide">&nbsp;Test</span>
                                </>
                              )}
                            </Button>
                          )}
                      </span>
                      <span>
                        {meta.exam_body && (
                          <Button
                            size="large"
                            variant="outlined"
                            disabled={loading}
                            onClick={resetExam}
                          >
                            <RefreshOutlined />
                            <span className="sm-hide"> Reset</span>
                          </Button>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        {/*   {subjects_fetched && <CustomModal data={toast} />} */}
      </section>
    </React.Fragment>
  );
};

export default Start;
