import React from "react";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import SeoModule from "../../../services/SeoModule";
import HttpService from "../../../services/HttpService";
import Box from "@mui/material/Box";
import DocsHome from "./DocsHome";
import DocsCategory from "./DocsCategory";
import DocsSub from "./DocsSub";
import AuthService from "../../../services/AuthService";
import "../../Header/Header.css";

const Docs = () => {
  const logx = AuthService.isLogged();
  const [isLogged, setIsLogged] = React.useState(logx);
  const [cur_slug, setSlug] = React.useState<any | string>(null);

  const doLogout = () => {};

  const [whitepaper, setDocs] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [togged, setTogged] = React.useState<boolean>(false);
  const togNav = () => {
    setTogged(!togged);
  };
  const page = useLocation()["pathname"];
  const pgarr = page.split("/");

  console.log(page, pgarr);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    listwhitepaper();
    if (pgarr.length == 4) {
      setSlug(pgarr[3]);
    }
  }, []);

  const listwhitepaper = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.getHeader("list_docs")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (Array.isArray(result.data)) {
            setDocs(result.data);
          } else {
            setDocs([]);
          }
        },
        (error) => {
          setDocs([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax
  return (
    <React.Fragment>
      <SeoModule
        title="Reva - Docs"
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />

      <Box
        sx={{
          display: "flex",
          flexGrow: "1 1",
          width: "100%",
          minHeight: "100vh",
          padding: "0 !important",
        }}
      >
        <section className="white-main">
          <div className="whitepaper-header-container">
            <span className="mobile-ham">
              <a onClick={togNav}>
                <i className="fas fa-bars"></i>
              </a>
            </span>
            <span className="ham-logo">
              <a href="/">
                <img src="/images/logo.png" alt="Logo" />
              </a>
            </span>
            <span className="spacer"></span>
            <div className="desk-show">
              <nav className="heder-menu ">NAv</nav>
            </div>
          </div>
          {/** header end */}
          <section className="whitepaper-body-container">
            <div
              className={`whitepaper-side-nav ${togged ? "expand-width" : ""}`}
            >
              {/* */}{" "}
              <ul className="whitepaper-list">
                {whitepaper.map((item: any, index: number) => (
                  <li key={item.title}>
                    <Link
                      to={`/docs/${item.slug}`}
                      onClick={() => setSlug(item.slug)}
                    >
                      <span className="uppercase">{item.title}</span>
                    </Link>
                    <ul>
                      {item.sub.map((itm: any, ind: number) => (
                        <li key={itm.title}>
                          <Link
                            className={cur_slug === itm.slug ? "active" : ""}
                            to={`/docs/${item.slug}/${itm.slug}`}
                            onClick={() => {
                              setSlug(itm.slug);
                              togNav();
                            }}
                          >
                            {itm.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
            <div className="whitepaper-page-content">
              <Routes>
                <Route path="" element={<DocsHome />} />
                <Route path="/:cat_slug" element={<DocsCategory />} />
                <Route path="/:cat_slug/:sub_slug" element={<DocsSub />} />
              </Routes>
            </div>
          </section>
        </section>
      </Box>
    </React.Fragment>
  );
};

export default Docs;
