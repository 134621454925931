import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { ButtonGroup, CircularProgress, Divider, Tooltip } from "@mui/material";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  CheckCircleOutline,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  CircleOutlined,
  CloseOutlined,
  DoneAll,
  ErrorOutlineRounded,
  InfoOutlined,
  PowerOffOutlined,
  Send,
  WarningAmberOutlined,
} from "@mui/icons-material";
import Timer from "../../templates/Timer";
import * as processHtml from "../../../services/processHtml";
import { MathJaxContext, MathJax } from "better-react-mathjax";
import ConfirmModal from "../../templates/ConfirmModal";
import CustomModal from "../../templates/CustomModal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type Anchor = "top" | "left" | "bottom" | "right";
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StudyPage = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("LOC:: ", location);
  console.log("Scores Review Rerenders:: ");
  const { decodeHtml, secondsToTime } = processHtml;
  const pgi = [0, 0, 0, 0];
  let params: any = useParams();
  const [isParam, setParam] = React.useState<any>(false);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [exam_fetched, setExamFetched] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [btn_disabled, setDisabled] = React.useState(false);
  const [exam_load, setExamLoad] = React.useState<any[]>([]);
  const [duration, setDuration] = React.useState<number>(0);
  const [load_page, setLoadPage] = React.useState<any[]>(pgi);
  const [activeStep, setActiveStep] = React.useState(0);
  const [question_loaded, setQuestionLoaded] = React.useState(false);
  const [test_ended, setTestEnded] = React.useState(false);
  const jumpPage = (index: number) => {
    const newr = [...load_page];
    newr[activeStep] = index;
    setLoadPage(newr);
    toggleDrawerClose();
  };
  const nextPage = () => {
    const newr = [...load_page];
    newr[activeStep] = newr[activeStep] + 1;
    setLoadPage(newr);
  };
  const prevPage = () => {
    const newr = [...load_page];
    newr[activeStep] = newr[activeStep] - 1;
    setLoadPage(newr);
  };

  const state = location.state;

  const [cur_item, setCurItem] = React.useState<any>({});
  React.useEffect(() => {
    if (exam_load.length > 0 && exam_load[activeStep]["question"].length > 0) {
      console.log(
        "activeStep, load_page::::::",
        activeStep,
        load_page[activeStep],
        exam_load[activeStep]
      );
      setCurItem(exam_load[activeStep]["question"][load_page[activeStep]]);
      setQuestionLoaded(true);
    }
  }, [activeStep, load_page, exam_load]); /* */

  React.useEffect(() => {
    console.log(":::params::", params);
    const isParam = params.sessionId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchStudy(params.sessionId);
    }
  }, []);

  const fetchStudy = (id: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("test_study", {
      mode: "test_review",
      session_id: id,
    })
      .then(
        (response) => {
          console.log("::", response);
          if (Array.isArray(response["data"])) {
            const resp = response["data"];
            setExamLoad(resp);
            setExamFetched(true);
            console.log(resp[activeStep]);
            if (resp.length > 0 && resp[activeStep]["question"].length > 0) {
              setCurItem(
                exam_load[activeStep]["question"][load_page[activeStep]]
              );
            } else {
              setExamLoad([]);
            }
          } else {
            setExamLoad([]);
          }
        },
        (error) => {
          setExamLoad([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //
  };

  const closeToast = () => {
    setToast({ ...toast, onopen: false });
  };
  const closeConfirm = () => {
    setConfirm({ ...toast, onopen: false });
  };

  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: closeToast,
  });
  const [confirm, setConfirm] = React.useState<any>({
    onopen: false,
    onclose: closeConfirm,
  });

  const handleStep = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setActiveStep(newValue);
  };
  /**/
  const handleAnswerChange = React.useCallback(
    (item: any, a: string, i: number, p: number) => {
      console.log(a, i);
      let newArr = [...exam_load];
      newArr[activeStep]["question"][i]["option_selected"] = a;
      newArr[activeStep]["question"][i]["attempted"] = true;
      setExamLoad(newArr); /**/

      console.log(exam_load[activeStep]["question"][i]);
    },
    [exam_load, load_page, activeStep]
  ); /* */

  const [drawer_open, setDrawer] = React.useState(false);
  const [preview, setPreview] = React.useState<any[]>([]);
  const toggleDrawerClose = () => {
    setDrawer(false);
    setPreview([]);
  };
  const toggleDrawer = () => {
    setDrawer(!drawer_open);
    //if (drawer_open) {
    setPreview(exam_load[activeStep]["question"]);
    //}
  };
  const [current_duration, setCurDur] = React.useState<number>(0);
  const handleReport = () => {};
  return (
    <React.Fragment key={`bottom`}>
      <MathJaxContext>
        <MathJax>
          {exam_fetched && !question_loaded && (
            <div className="load-error py30">
              <div className="load-error-icon flex flex-col align-items-center justify-content-center">
                <ErrorOutlineRounded
                  sx={{ fontSize: "50px" }}
                  fontSize="large"
                />
              </div>
              <h2>
                Error Loading Question. Please Select other exam year or
                subjects(s){" "}
              </h2>
            </div>
          )}
          {exam_fetched && question_loaded && (
            <>
              <section className="px0">
                <Box sx={{ width: "100%" }}>
                  <div className="bg-white pxy0">
                    <Tabs
                      value={activeStep}
                      variant="scrollable"
                      scrollButtons
                      onChange={handleStep}
                      aria-label="basic tabs example"
                    >
                      {exam_load.map((item: any, index: number) => (
                        <Tab
                          key={item.subject_title}
                          label={item.subject_title}
                          {...a11yProps(index)}
                        />
                      ))}
                    </Tabs>
                  </div>
                  <Divider />

                  {exam_load[activeStep]["question"] &&
                    exam_load[activeStep]["question"].length <= 0 && (
                      <div className="load-error">
                        <span className="load-error-icon">
                          <ErrorOutlineRounded
                            fontSize="large"
                            sx={{ fontSize: "2em" }}
                          />
                        </span>
                        <h3>
                          No questions found for{" "}
                          <u>{state.picked_subjects[activeStep].title}.</u>
                        </h3>
                      </div>
                    )}

                  {exam_load[activeStep]["question"] &&
                    exam_load[activeStep]["question"].length > 0 && (
                      <>
                        {/**NAV Ends */}
                        <TabPanel value={activeStep} index={activeStep}>
                          <Slide
                            direction="right"
                            in={load_page[activeStep] === cur_item.index}
                          >
                            <div className="px20 py10">
                              <Card sx={{ p: "0" }}>
                                <section className="pxy20 flex flex-row">
                                  <span className="num-text">
                                    {load_page[activeStep] + 1}
                                  </span>
                                  <div className="pl20 pb10 flex flex-col spacer">
                                    {exam_load[activeStep]["year_mode"] ===
                                      "all" && (
                                      <div className="date-span">
                                        {cur_item["exam_body"].toUpperCase()}{" "}
                                        {cur_item["exam_year"]}
                                      </div>
                                    )}
                                    <div className="mb10 question_pane">
                                      {cur_item.photo && (
                                        <div className="question-photo">
                                          <img
                                            src={`${process.env.REACT_APP_SERVER_FILES_DOMAIN}files/test/question/${cur_item.photo}`}
                                            alt="question-picture"
                                          />
                                        </div>
                                      )}

                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: decodeHtml(
                                            cur_item["question"]
                                          ),
                                        }}
                                      ></div>
                                    </div>
                                    <section className="answer-container">
                                      <div
                                        className={
                                          cur_item.selected_option === "a"
                                            ? `option-container active bordered`
                                            : `option-container bordered`
                                        }
                                      >
                                        <span className="alpha-span">A</span>

                                        <div
                                          className="spacer text-left"
                                          dangerouslySetInnerHTML={{
                                            __html: decodeHtml(
                                              cur_item.option_a
                                            ),
                                          }}
                                        ></div>
                                        <span className="check-span">
                                          {cur_item.correct_option === "a" ? (
                                            <DoneAll
                                              sx={{
                                                color: "rgb(86, 136, 94)",
                                              }}
                                            />
                                          ) : (
                                            <CloseOutlined color="error" />
                                          )}
                                        </span>
                                      </div>{" "}
                                      <div
                                        className={
                                          cur_item.selected_option === "b"
                                            ? `option-container active bordered`
                                            : `option-container bordered`
                                        }
                                      >
                                        <span className="alpha-span">B</span>

                                        <div
                                          className="spacer text-left"
                                          dangerouslySetInnerHTML={{
                                            __html: decodeHtml(
                                              cur_item.option_b
                                            ),
                                          }}
                                        ></div>
                                        <span className="check-span">
                                          {cur_item.correct_option === "b" ? (
                                            <DoneAll
                                              sx={{
                                                color: "rgb(86, 136, 94)",
                                              }}
                                            />
                                          ) : (
                                            <CloseOutlined color="error" />
                                          )}
                                        </span>
                                      </div>{" "}
                                      <div
                                        className={
                                          cur_item.selected_option === "c"
                                            ? `option-container active bordered`
                                            : `option-container bordered`
                                        }
                                      >
                                        <span className="alpha-span">C</span>

                                        <div
                                          className="spacer text-left"
                                          dangerouslySetInnerHTML={{
                                            __html: decodeHtml(
                                              cur_item.option_c
                                            ),
                                          }}
                                        ></div>
                                        <span className="check-span">
                                          {cur_item.correct_option === "c" ? (
                                            <DoneAll
                                              sx={{
                                                color: "rgb(86, 136, 94)",
                                              }}
                                            />
                                          ) : (
                                            <CloseOutlined color="error" />
                                          )}
                                        </span>
                                      </div>{" "}
                                      <div
                                        className={
                                          cur_item.selected_option === "d"
                                            ? `option-container active bordered`
                                            : `option-container bordered`
                                        }
                                      >
                                        <span className="alpha-span">D</span>

                                        <div
                                          className="spacer text-left"
                                          dangerouslySetInnerHTML={{
                                            __html: decodeHtml(
                                              cur_item.option_d
                                            ),
                                          }}
                                        ></div>
                                        <span className="check-span">
                                          {cur_item.correct_option === "d" ? (
                                            <DoneAll
                                              sx={{
                                                color: "rgb(86, 136, 94)",
                                              }}
                                            />
                                          ) : (
                                            <CloseOutlined color="error" />
                                          )}
                                        </span>
                                      </div>{" "}
                                      {cur_item.option_e && (
                                        <div
                                          className={
                                            cur_item.selected_option === "e"
                                              ? `option-container active bordered`
                                              : `option-container bordered`
                                          }
                                        >
                                          <span className="alpha-span">E</span>

                                          <div
                                            className="spacer text-left"
                                            dangerouslySetInnerHTML={{
                                              __html: decodeHtml(
                                                cur_item.option_e
                                              ),
                                            }}
                                          ></div>
                                          <span className="check-span">
                                            {cur_item.correct_option === "e" ? (
                                              <DoneAll
                                                sx={{
                                                  color: "rgb(86, 136, 94)",
                                                }}
                                              />
                                            ) : (
                                              <CloseOutlined color="error" />
                                            )}
                                          </span>
                                        </div>
                                      )}
                                      {cur_item.explanation && (
                                        <div className="explanation-space">
                                          <h3 className="bolder">
                                            Answer Explanation:
                                          </h3>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: decodeHtml(
                                                cur_item.explanation
                                              ),
                                            }}
                                          ></p>
                                        </div>
                                      )}
                                    </section>
                                  </div>
                                </section>
                              </Card>
                            </div>
                          </Slide>
                        </TabPanel>

                        <div className="flex flex-row align-items-center px20">
                          <span className="spacer ">
                            <Button
                              onClick={handleReport}
                              variant="outlined"
                              size="medium"
                              color="warning"
                            >
                              <span className="sm-hide">Report Error </span>{" "}
                              <InfoOutlined sx={{ fontSize: "17px" }} />
                            </Button>
                          </span>
                          <span className="text-center">
                            <Tooltip title="Jump to question">
                              <Button
                                onClick={toggleDrawer}
                                color="primary"
                                size="large"
                              >
                                <span className="sm-hide">
                                  JUMP TO QUESTION{" "}
                                </span>{" "}
                                {drawer_open ? (
                                  <ArrowDropDownOutlined />
                                ) : (
                                  <ArrowDropUpOutlined />
                                )}
                              </Button>
                            </Tooltip>
                          </span>
                          <span className=" spacer text-right">
                            <ButtonGroup>
                              {load_page[activeStep] > 0 && (
                                <Tooltip title="Back to previous question">
                                  <Button
                                    disabled={btn_disabled}
                                    onClick={() => prevPage()}
                                  >
                                    <ChevronLeftOutlined />
                                    <span className="sm-hide"> Previous</span>
                                  </Button>
                                </Tooltip>
                              )}
                              {load_page[activeStep] <
                                exam_load[activeStep]["question"].length -
                                  1 && (
                                <Tooltip title="Move to next question">
                                  <Button
                                    disabled={btn_disabled}
                                    onClick={() => nextPage()}
                                  >
                                    <span className="sm-hide">Next </span>
                                    <ChevronRightOutlined />
                                  </Button>
                                </Tooltip>
                              )}
                            </ButtonGroup>
                          </span>
                        </div>
                      </>
                    )}
                </Box>
              </section>
              {exam_load[activeStep]["question"] &&
                exam_load[activeStep]["question"].length > 0 && (
                  <Drawer
                    anchor={`bottom`}
                    open={drawer_open}
                    onClose={toggleDrawerClose}
                  >
                    <div className="flex flex-column justify-content-center align-items-center">
                      <Tooltip title="Close Panel">
                        <Button
                          onClick={toggleDrawer}
                          color="primary"
                          size="large"
                        >
                          <span className="sm-hide">CLOSE </span>{" "}
                          {drawer_open ? (
                            <ArrowDropDownOutlined />
                          ) : (
                            <ArrowDropUpOutlined />
                          )}
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="prev-container">
                      {preview.map((item: any, index: number) => (
                        <a
                          onClick={() => jumpPage(index)}
                          key={index}
                          className={item.attempted ? "active" : "not"}
                        >
                          <span className="p-sel">
                            {item.selected_option === item.correct_option ? (
                              <i className="fas fa-check-circle green"></i>
                            ) : (
                              <i className="fas fa-circle red"></i>
                            )}
                          </span>
                          {index + 1}
                          {item.selected_option && (
                            <span className="o-sel">{`${item.selected_option}`}</span>
                          )}
                        </a>
                      ))}
                    </div>
                  </Drawer>
                )}
            </>
          )}

          {loading && (
            <div className="flex flex-column flex-col  text-center align-items-center py20">
              <span className="pxy20 text-center">
                <CircularProgress size={50} />
              </span>
              <div className="text-center txt-lg">Loading test....</div>
            </div>
          )}
        </MathJax>
      </MathJaxContext>
      <ConfirmModal data={confirm} />
      <CustomModal data={toast} />
    </React.Fragment>
  );
};

export default StudyPage;
