import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import ScoresList from "./ScoresList";
import ScoresDetail from "./ScoresDetail";
import ScoresReview from "./ScoresReview";
const Scores = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/account/news/a/new`);
  };
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<ScoresList launchNew={launchNew} />} />
        <Route path="/" element={<ScoresList launchNew={launchNew} />} />
        <Route
          path="/v/:testId"
          element={<ScoresDetail launchNew={launchNew} />}
        />
        <Route
          path="/r/:testId"
          element={<ScoresReview launchNew={launchNew} />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default Scores;
