import React from "react";
import Pusher from "pusher-js";
import HttpService from "../../services/HttpService";
import {
  Avatar,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import DatePipe from "../../pipes/DatePipe";
import { uptime } from "process";
import Ordinal from "../../pipes/Ordinal";

const LeaderBoard = (props: any) => {
  const [feed, setFeed] = React.useState<any>([]);

  React.useEffect(() => {
    const pusher = new Pusher("94b15ec0f11a47b7d711", {
      cluster: "eu",
      //encrypted: true,
      forceTLS: true,
    });
    const channel = pusher.subscribe("my-channel");
    channel.bind("my-event", fetchFeed);
    fetchFeed(offset);
  }, []);
  const [loading, setLoading] = React.useState<any>(false);
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);

  const fetchFeed = (offsetx: number) => {
    setLoading(true);
    HttpService.postHeader("leaderboard", {
      mode: "getleaders",
      limit: limit,
      offset: offsetx,
    })
      .then(
        (resp) => {
          if (Array.isArray(resp.data)) {
            let newRes = [...feed, ...resp.data];
            setFeed(newRes);
          }
        },
        (error) => {
          setFeed(feed);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const loadMore = () => {
    const newOffset = offset + limit;
    setOffset(newOffset);
    fetchFeed(newOffset);
  };

  /**
   * var client = new Pusher('94b15ec0f11a47b7d711', {
      cluster: 'eu',
      forceTLS: true
    });
var pusher = $pusher(client);
var my_channel = pusher.subscribe('my-channel');
my_channel.bind('my-event',pushComm);
   */
  return (
    <React.Fragment>
      <section className="pxy20">
        {loading && <div>Loading feed...</div>}
        <Card>
          {feed.map((item: any, index: number) => (
            <ListItem
              key={index}
              divider
              secondaryAction={
                <>
                  <i className="fas fa-arrow-up"></i> <span>{item.passed}</span>
                </>
              }
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    src={process.env.REACT_APP_SERVER_DOMAIN + item.avatar}
                    variant="circular"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={<h3>{item.name}</h3>}
                  /*    secondary={item.passed} */
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </Card>
      </section>
    </React.Fragment>
  );
};
export default LeaderBoard;
