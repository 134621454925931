import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import "./Header.css";
const Header = () => {
  const [isTogged, setIsTogged] = React.useState(false);
  const page = useLocation().pathname;
  const logx = AuthService.isLogged();
  const usr = AuthService.getCurrentUser();
  const [isLogged, setIsLogged] = React.useState(logx);
  const navigate = useNavigate();

  const doLogout = () => {
    AuthService.logout();
    setTimeout(() => {
      console.log("Session Cleared...");
      setIsLogged(false);
      return navigate("/");
    }, 300);
  };

  const toggleNav = () => {
    const cur = isTogged;
    setIsTogged(!cur);
  };

  const doBoth = () => {
    doLogout();
    toggleNav();
  };

  const showMobileNav: boolean = isTogged ? true : false;

  return (
    <React.Fragment>
      <main className="container top-index">
        <header className="header">
          <div className="logo">
            <NavLink to="/">
              <img
                src={
                  `/images/logo.png` /*page === "/" ? 
  "/images/logo2.png" : "/images/logo.png"*/
                }
                alt="Cyprex Logo"
              />
            </NavLink>
          </div>
          <span className="spacer"></span>

          <nav className={"" /*page === "/" ? "home-color" : ""*/}>
            <ul>
              <li>
                <NavLink to={"/about"}>About Us</NavLink>
                <ul>
                  <li>
                    <NavLink to="/about-us">
                      <span className="iconner">
                        <i className="fas fa-cogs"></i>
                      </span>
                      <span className="link-info">
                        <span className="header">About Us</span>
                        <span className="summary">Our company profile</span>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/team">
                      <span className="iconner">
                        <i className="fas fa-users"></i>
                      </span>
                      <span className="link-info">
                        <span className="header">Our Team</span>
                        <span className="summary">Cyprex Personnel</span>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us#mission">
                      <span className="iconner">
                        <i className="fas fa-flag"></i>
                      </span>
                      <span className="link-info">
                        <span className="header">Our Mission</span>
                        <span className="summary">Mission Statement</span>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/faq">
                      <span className="iconner">
                        <i className="fas fa-question-circle"></i>
                      </span>
                      <span className="link-info">
                        <span className="header">FAQ</span>
                        <span className="summary">
                          Frequently asked question
                        </span>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/docs">Docs</NavLink>
              </li>
              <li>
                <NavLink to="/portfolio">Portfolio</NavLink>
              </li>
              <li>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>
            </ul>
            <div className={"top-cta"}>
              {!isLogged ? (
                <>
                  <Link to="/login" className="sign-up">
                    Log In
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/admin/dashboard" className="sign-up">
                    Dashboard
                  </Link>
                </>
              )}
            </div>
          </nav>
        </header>
      </main>
    </React.Fragment>
  );
};

export default Header;
