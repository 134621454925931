import React from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import HttpService from "../../../services/HttpService";
import DatePipe from "../../../pipes/DatePipe";
import Add from "@mui/icons-material/Add";
import Search from "@mui/icons-material/Search";
import MailOutline from "@mui/icons-material/MailOutline";

const MessagesList = (props: any) => {
  const { launchNew } = props;
  const [messages, setMessages] = React.useState<any[]>([]);
  const [result_loaded, setResultLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const location = useLocation();

  React.useEffect(() => {
    doAjax();
  }, []); //componentDidMount

  const doAjax = () => {
    setLoading(true);
    HttpService.post({ mode: "all" }, "messages").then(
      (result) => {
        console.log(result);
        setMessages(result.data);
        setResultLoaded(true);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setResultLoaded(true);
        setError(error.message);
      }
    ); //fetch
  }; //doAjax

  return (
    <React.Fragment>
      {!loading && (
        <>
          <div
            className="page-top-intro flex px20 py10 align-items-center 
      justify-content-center"
          >
            <div className="px10">
              <h3 className="py0 my0 px0 mx0">
                <i className="fas fa-messages"></i> messages
              </h3>
            </div>
            <div className="spacer"></div>
            <div>
              {" "}
              <Tooltip title="Search messages">
                <IconButton>
                  <Search />
                </IconButton>
              </Tooltip>{" "}
              <Tooltip title="Create new">
                <IconButton onClick={launchNew}>
                  <Add />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <Divider />

          <List sx={{ paddingTop: "0px", paddingBotom: "0px" }}>
            {messages.map((item: any, index: number) => (
              <ListItem
                disablePadding
                button
                key={item.id}
                divider={true}
                component={NavLink}
                to={`/account/messages/${item.id}`}
                secondaryAction={
                  <>
                    <span>
                      <DatePipe value={item.tdate} />
                    </span>
                  </>
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MailOutline />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component={"h2"}>{item.subject}</Typography>{" "}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          {result_loaded && messages.length === 0 && (
            <div className="result-error">
              <span>
                <i className="fas fa-exclamation-triangle"></i>
              </span>
              <h3> No messages found!</h3>
            </div>
          )}
        </>
      )}
      {loading && (
        <div className="pxy20">
          <Card className="pxy20">
            <PlaceHolder type="list" />
            <Divider />
            <PlaceHolder type="list" />
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default MessagesList;
