import axios from "axios";
import authHeader from "./AuthHeaders";

const API_URL = process.env.REACT_APP_API_DOMAIN;
const API_SECRET = process.env.REACT_APP_API_SECRET;

type headerType = {
  Authorization?: string;
  Bearer?: string;
};
class HttpService {
  auh: headerType = authHeader();

  findAccount(load: any) {
    const url = API_URL + "/auth" + "?secret=" + API_SECRET;
    return axios.post(url, load).then((response) => {
      return response.data;
    });
  }

  post(data: any, endpoint: string) {
    const url = API_URL + endpoint + "?secret=" + API_SECRET;
    return axios.post(url, data).then((response) => {
      return response.data;
    });
  }
  get(endpoint: string) {
    const url = API_URL + endpoint + "?secret=" + API_SECRET;
    return axios.post(url).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  postHeader(endpoint: string, data: any) {
    const url = API_URL + endpoint + "?secret=" + API_SECRET;
    return axios.post(url, data, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  postForm(endpoint: string, data: any) {
    const obh = { "content-type": "multipart/form-data" };
    const url = API_URL + endpoint + "?secret=" + API_SECRET;
    return axios.post(url, data, { headers: obh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  postFormHeader(endpoint: string, data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    const url = API_URL + endpoint + "?secret=" + API_SECRET;
    return axios.post(url, data, { headers: hrs }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  getHeader(endpoint: string) {
    const url = API_URL + endpoint + "?secret=" + API_SECRET;
    return axios.get(url, { headers: this.auh }).then((response) => {
      let resp = response.data;
      console.log(resp);
      return resp;
    });
  }
}
export default new HttpService();
