import * as React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import Paper from "@mui/material/Paper";
import { Button, Card, CircularProgress, Divider, Icon } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid"; // Grid version 1
import { secondsToTime } from "../../../services/processHtml";

const ScoresDetail = (props: any) => {
  //console.log("ScoresReview");
  const location = useLocation();
  let params: any = useParams();
  //console.log("params", params);
  const [performance, setPerformance] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState<any>(false);

  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.testId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchDetails(params.testId);
    }
  }, []);

  const fetchDetails = (id: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("test_result_details", {
      mode: "score_details",
      exam_id: id,
    })
      .then(
        (resp) => {
          console.log("scores_response:::", resp);
          if (Array.isArray(resp.data)) {
            setPerformance(resp.data);
          }
        },
        (error) => {
          setPerformance([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //getScores
  };

  return (
    <React.Fragment>
      <section className="pxy20">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          columns={{ xs: 1, sm: 4, md: 6 }}
        >
          {performance.map((item: any, index: number) => (
            <Grid item xs={1} sm={4} md={6} key={index}>
              <section>
                <Card>
                  <div className="score-cover">
                    <div className="py10 flex flex-row align-items-center">
                      <h3>
                        <i className="fas fa-book"></i> {item.title} (
                        {item.exam_year})
                      </h3>
                      <span className="spacer"></span>
                      <span className="px10">{item.exam_id}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Total</span>{" "}
                      <span>{item.test_num}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Attempted</span>{" "}
                      <span>{item.attempted}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Skipped</span>{" "}
                      <span>{item.skipped}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Passed</span>{" "}
                      <span>{item.passed}</span>
                    </div>
                    <div className="score-line">
                      <span className="spacer skd">Failed</span>{" "}
                      <span>{item.failed}</span>
                    </div>
                    <span className="score-line">
                      <span className="spacer skd">
                        <i className="fas fa-clock"></i> Duration
                      </span>{" "}
                      <span>
                        {secondsToTime(item.used_duration)}/
                        {secondsToTime(item.assigned_duration)}
                      </span>
                    </span>
                  </div>

                  <Divider />
                  <div>
                    <Link
                      to={`/account/scores/r/${item.subject_id}:${item.exam_id}`}
                    >
                      <Button size="large" color="primary" fullWidth>
                        <Icon>&#xE417;</Icon> &nbsp;Review Answers
                      </Button>
                    </Link>
                  </div>
                </Card>
              </section>
            </Grid>
          ))}
        </Grid>

        {loading && (
          <div className="flex flex-column flex-col  text-center align-items-center py20">
            <span className="pxy20 text-center">
              <CircularProgress size={50} />
            </span>
            <div className="text-center txt-lg">Loading score details....</div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default ScoresDetail;
