import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeoModule from "../../../services/SeoModule";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import LinearProgress from "@mui/material/LinearProgress";

const Privacy = () => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { page, loaded_page, loading_page } = useFetchPage({ slug: "privacy" });
  return (
    <React.Fragment>
      <SeoModule
        title="Reva - Privacy Policy"
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/">Home</Link>
                    <span>{loading_page ? "Loading..." : page.title}</span>
                  </Breadcrumbs>
                  <h2 className="mt20">
                    {loading_page ? "Loading..." : page.title}
                  </h2>
                </div>
              </div>
            </div>

            <div className="pxy20">
              {loading_page && <LinearProgress />}
              {!loading_page && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(page.description),
                  }}
                ></div>
              )}
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Privacy;
